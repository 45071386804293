import React, { useRef, useState } from "react";
import { Select, Typography, Row, Col, Switch } from "antd";
import ReactToPrint from 'react-to-print';
import { Button, Container, styled } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';

const { Option } = Select;

// Define the available firms
const uitable = ["UNIQUE FIRM", "SOHAM FIRM"];

const StyledButton = styled(Button)({
    marginTop: 20,
    backgroundColor: 'rgb(100, 113, 60)',
    color: '#fff',
});

const StyledSwitch = styled(Switch)(({ theme }) => ({
    '.ant-switch': {
        backgroundColor: '#2196F3',
        borderRadius: '50px',
        width: '50px',
        height: '25px',
        padding: '3px',
        transition: 'background-color 0.3s ease',
    },
    '.ant-switch-checked': {
        backgroundColor: '#4CAF50',
    },
    '.ant-switch-inner': {
        fontSize: '14px',
        fontWeight: 'bold',
    },
}));

const FirmSelector = ({ setSelecteduitablee, printRef, showTotal, setShowTotal, deliveryChallan }: any) => {
    const [selecteduitable, setSelecteduitable] = useState<string>("UNIQUE FIRM");


    // Handle firm selection change
    const handleuitableChange = (value: string) => {
        setSelecteduitable(value);
        setSelecteduitablee(value); // Update parent state if needed
    };
    const handleToggleTotal = (checked: boolean) => {
        setShowTotal(checked); // Update the state based on switch toggle
    };
    return (
        <Row gutter={16}>
            {
                !deliveryChallan.firm &&
                <Col xs={24} sm={12} md={8} lg={6}>
                <Typography.Title level={4}>Select Firm</Typography.Title>
                <Select
                    placeholder="Select uitable"
                    value={selecteduitable}
                    onChange={handleuitableChange}
                    style={{ width: "100%" }}
                >
                    {/* Always show "All" */}
                    {/* <Option key="All" value="All">
                    UNIQUE FIRM
                    </Option> */}
                    {/* Show only the selected firm */}
                    {uitable.map((uitable) => (
                        <Option key={uitable} value={uitable}>
                            {uitable}
                        </Option>
                    ))}
                </Select>
            </Col>
            }
            
            <Col xs={24} sm={12} md={6} lg={3}>
                <br />
                <br />
                <ReactToPrint
                    trigger={() => (
                        // <Button variant="contained" color="primary" style={{ marginTop: '20px' }}>
                        //     Print
                        // </Button>
                        <StyledButton variant="contained" startIcon={<PrintIcon />}>
                            Print
                        </StyledButton>
                    )}
                    content={() => printRef.current} // Content to print
                />
            </Col>
            <Col xs={24} sm={12} md={6} lg={5}>
                <br /><br /><br />
                <div className="dc-toggle-switch">
                    <span style={{ fontSize: '15px', fontWeight: '600' }}>Show Total: </span>
                    <StyledSwitch
                        checked={showTotal}
                        onChange={handleToggleTotal}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                    />
                </div>
            </Col>
        </Row>
    );
};

export default FirmSelector;
