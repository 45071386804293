import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Autocomplete, CircularProgress,Button,IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import debounce from 'lodash/debounce';
import { get_product_search,get_product_search_with_quotation } from "../services/order";
import ProductDescribe from "./ProductDescribe";
import AddIcon from "@mui/icons-material/Add";
import ProductCreate from '../../product/components/Product';

interface ProductOption {
  id: string;
  name: string;
}

const ProductSearch = ({purchaseOrderId, onSelectToOrder, orderProducts, companyId,setOrderProducts, withGst, units }: any) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [options, setOptions] = useState<ProductOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [value, setValue] = useState<ProductOption | null>(null);  // Added state for controlling selected value
  const [optionsData, setOptionData] = useState<any[]>([]);
  const [selectedProductDetails, setSelectedProductDetails] = useState<any>();
  const fetchProducts = useCallback(
    debounce(async (query: string) => {
      if (query.length >= 1) { // Trigger search after 3 characters
        setLoading(true);
        try {
          
          let response: any = null;
          // if(purchaseOrderId){
          //   response = await get_product_search_with_quotation(query,purchaseOrderId,companyId);
          // }else{
            response = await get_product_search(query,companyId);
          // }
          

          setOptionData(response.data?.data?.length ? response.data?.data : []);
          
          if (response.data?.data?.length) {
            const options = response.data.data.map((e: any) => ({
              id: e.id,
              name: e.productName + " "+ e.companyProductCode
            }));
            setOptions(options);
          } else {
            setOptions([]);
          }
        } catch (error) {
          // setOptions([]);
        } finally {
          setLoading(false);
        }
      } else {
        // setOptionData([]);
        // setOptions([]);
      }
    }, 300),
    [purchaseOrderId,companyId]
  );

  useEffect(() => {
    fetchProducts(searchTerm);
  }, [searchTerm, fetchProducts]);

  const onChangeProductSearch = (productOption: ProductOption | null) => {
    setInputValue('');
    if (productOption && optionsData.length > 0) {
      const product = optionsData.find((item: any) => item.id === productOption.id);
      if (product?.product_attributes?.length) {
          setSelectedProductDetails(product);
      }
    }
  };

  useEffect(() => {
    if (orderProducts.length === 0) {
      setSelectedProductDetails(undefined);
      setInputValue('');
    }
  }, [orderProducts.length]);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  return (
    <div style={{ width: "100%", }}>
      <div style={{display:"flex"}}>
        <div style={{ width: "95%" }}>
          <Autocomplete
            options={options}
            getOptionLabel={(option) => option.name}
            loading={loading}
            size="small"
            inputValue={inputValue}
            value={value}  // Control the selected value
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              setSearchTerm(newInputValue);
            }}
            onChange={(event, newValue) => {
              onChangeProductSearch(newValue);
              setValue(null);  // Clear the selected value after selection
              setInputValue('');  // Clear input field
              setSearchTerm('');  // Clear search term
              // setOptionData([]);  // Clear option data
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Product"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            noOptionsText="No products found"
          />
        </div>
        <div style={{ width: "5%" }}>
        <IconButton onClick={handleDialogOpen} aria-label="add" sx={{ marginLeft: 2 }}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
     
     

      <div style={{ width: "100%" }}>
        <ProductDescribe
          product={selectedProductDetails}
          onSelectToOrder={onSelectToOrder}
          orderProducts={orderProducts}
        />
      </div>
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="lg">
        <DialogTitle>Create New Product</DialogTitle>
        <DialogContent>
       <ProductCreate setSelectedProductDetails={setSelectedProductDetails} 
       setDialogOpen={setDialogOpen} purchaseOrderId={purchaseOrderId} />
        </DialogContent>
        
      </Dialog>
    </div>
  );
};

export default ProductSearch;
