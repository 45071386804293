import React, { useEffect, useState } from "react";
import "./DcTable.css";
import { delivery_challanapi } from "../../services/order";
import { fontSize } from "@mui/system";


const fontsizedata = {
    fontSize: '11px'
}

const DcTable = ({ selecteduitable, showTotal,
    rows,loading, error
 }: any) => {
    
    console.log("data", rows);

    

    return (
        <div className="dc-table-container">
            {loading && <p>Loading...</p>}
            {error && <p className="error">{error}</p>}
            {!loading && !error && (
                <table className={selecteduitable === "SOHAM FIRM" ? "dcsoham-table" : "dc-table"}>
                    <thead>
                        <tr>
                            <th style={{ width: "70px" }}>Sr. No.</th>
                            <th style={{ width: "auto" }}>Name of the Item</th>
                            <th style={{ width: "70px" }}>Nos. Qty.</th>
                            <th style={{ width: "70px" }}>Packing</th>
                            {
                                showTotal && <th style={{ width: "70px" }}>Price</th>}
                            <th style={{ width: "140px" }}>Make</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row:any, index:any) => (
                            <tr key={index}>
                                <td style={fontsizedata}>{row.srNo || index + 1}</td>
                                <td style={fontsizedata}>{row.product_name || ""}</td>
                                <td style={fontsizedata}>{row.quotationQty || ""}</td>
                                <td style={fontsizedata}>{row.qty || ""} {row.unit_name || ""}</td>
                               {
                                showTotal && <td style={fontsizedata}>{row.totalPrice || ""}</td>
                               } 
                                <td style={fontsizedata}>{row.company_name || ""}</td>
                            </tr>
                        ))}
                        {showTotal && (
                            <tr>
                                <td colSpan={5} style={{ textAlign: "right", fontWeight: "bold" }}>Total</td>
                                <td style={{ fontWeight: "bold" }}>
                                    {(rows.reduce((total:any, row:any) => total + (typeof row.totalPrice == "number" ? row.totalPrice : parseFloat(row.totalPrice || "0")), 0)).toFixed(2)}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
            <div className="dccondition-footer">
                <span>Received above material in good condition. No objection</span>
            </div>
            {selecteduitable === "SOHAM FIRM" ? (
                <div className="dc-footer">
                    <span>Receiver's Signature</span>
                    <label>For Soham Scientific<br/><br/><br/>
                    <span>Kolhapur</span></label>
                </div>
            ) : (
                <div className="dc-footer">
                    <span>Receiver's Signature</span>
                    <label>For Unique Biological & Chemicals <br/><br/><br/>
                    <span>Kolhapur</span></label>
                    
                </div>
            )}
        </div>
    );
};

export default DcTable;
