import React, { useState, useEffect } from 'react';
import { Modal, Table, Button, Form, Input, InputNumber, message } from 'antd';
import { billing_info_fetch_by_id,billing_info_list,billing_info_create,billing_info_update } from '../services/order'; 
import { render } from '@testing-library/react';
import dayjs, { Dayjs } from 'dayjs';
const BillingInfoModal = ({purchaseOrderId,isModalVisible, setIsModalVisible}:any) => {
  
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [billingData, setBillingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [editingRecord, setEditingRecord] = useState<any>(null);
  // Fetch Billing List
  const fetchBillingData = async () => {
    try {
      setLoading(true);
      const response:any = await billing_info_list(purchaseOrderId)
      setBillingData(response.data.data || []);
    } catch (error) {
      message.error('Failed to fetch billing data');
    } finally {
      setLoading(false);
    }
  };

 useEffect(()=>{
    if(purchaseOrderId){
        fetchBillingData();
    }
 },[purchaseOrderId,isModalVisible]);

  const handleModalClose = () => {
    setIsModalVisible(false);
    setBillingData([]);
  };

  // Handle Form Open/Close
  const handleFormOpen = (record:any = null) => {
    setEditingRecord(record);
    setIsFormVisible(true);
    form.resetFields();
    if (record) {
      const formattedRecord = {
        ...record,
        date_receive_amt: record?.date_receive_amt
          ?  dayjs(record?.date_receive_amt).format('YYYY-MM-DD')
          : null, // Format the date as 'yyyy-MM-dd'
      };
      form.setFieldsValue(formattedRecord);
    }
  };

  const handleFormClose = () => {
    setIsFormVisible(false);
    setEditingRecord(null);
  };

  const handleFormSubmit = async (values: any) => {
    try {
      const payload = {
        ...values,
        date_receive_amt: values.date_receive_amt
          ? new Date(values.date_receive_amt).getTime()
          : null, // Convert date to timestamp in milliseconds
      };
  
      if (editingRecord) {
        // Update Record
        await billing_info_update(payload, editingRecord.id);
        message.success('Record updated successfully');
      } else {
        // Insert Record
        await billing_info_create({ ...payload, purchaseOrderId });
        message.success('Record inserted successfully');
      }
      handleFormClose();
      fetchBillingData();
    } catch (error) {
      message.error('Failed to save record');
    }
  };
  

  const columns = [
    {
      title: 'Bill No',
      dataIndex: 'billNo',
      key: 'billNo',
    },
    {
      title: 'Bill Amount',
      dataIndex: 'billAmount',
      key: 'billAmount',
    },
    {
      title: 'Payment Received',
      dataIndex: 'payment_receive_amt',
      key: 'payment_receive_amt',
    },
    {
      title: 'Date Received',
      dataIndex: 'date_receive_amt',
      key: 'date_receive_amt',
      render:(text:string) => (
        <>{text && new Date(text).toLocaleDateString('en-GB')}</>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: any) => (
        <Button type="link" onClick={() => handleFormOpen(record)}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <>
     
      <Modal
        title={"Billing Information of Purchase Order No ( PO"+purchaseOrderId+")"}
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width="80%"
      >
        <Button type="primary" onClick={() => handleFormOpen()} style={{ marginBottom: 16 }}>
          Add New
        </Button>
        <Table
          columns={columns}
          dataSource={billingData}
          loading={loading}
          rowKey="id"
        />
        {isFormVisible && (
          <Modal
            title={editingRecord ? 'Update Billing Info' : 'Add Billing Info'}
            visible={isFormVisible}
            onCancel={handleFormClose}
            footer={null}
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={handleFormSubmit}
            >
              <Form.Item
                name="billNo"
                label="Bill No"
                rules={[{ required: true, message: 'Please enter Bill No' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="billAmount"
                label="Bill Amount"
                rules={[{ required: true, message: 'Please enter Bill Amount' }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name="payment_receive_amt"
                label="Payment Received Amount"
                rules={[{ required: true, message: 'Please enter Payment Received Amount' }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name="date_receive_amt"
                label="Date Received"
                rules={[{ required: true, message: 'Please enter Date Received' }]}
              >
                <Input type="date" />
              </Form.Item>
              <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                Save
              </Button>
              <Button onClick={handleFormClose}>Cancel</Button>
            </Form>
          </Modal>
        )}
      </Modal>
    </>
  );
};

export default BillingInfoModal;
