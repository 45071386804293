import React from 'react';
import OrderListUI from "../../components/admin/purchase_order/components/OrderList";
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
const PurchaseOrderList = () => {
  return (
    <div>
   <PersistentDrawerLeft>
      <OrderListUI/>
     </PersistentDrawerLeft>
    </div>
  );
};

export default PurchaseOrderList;