import React, { useState } from 'react';
import { Row, Col, Typography, Space, Input, Divider } from 'antd';
import { EnvironmentOutlined, PhoneOutlined, WhatsAppOutlined, MailOutlined } from '@ant-design/icons';
import Sohamlogo from "../../components/reusable/SOHAM_LOGO.png";
import logo from "../../components/reusable/logo-chemical.png";

interface SohamHeaderProps {
    width: number; // Define the type for width as number,
    selecteduitable: any;
    order: any;
    title?:any;
}

const { Text } = Typography;

const SohamHeader: React.FC<SohamHeaderProps> = ({ order, width, selecteduitable,title }) => {
    return (
        <>
            <br />
            <Row
                style={{
                    background: 'white',
                    // background: '#a6a6c0;'
                    // padding: '10px 0',
                    alignItems: 'center',
                    width: `${width}%`,
                    // clipPath: 'polygon(0px 0px, 0% 98%, 15% 0px)'
                }}
            >
                {/* Logo Section */}

                <Col xs={16} sm={16} md={18} style={{ textAlign: 'left' }}>
                    <div style={{
                        background: '#a6a6c0',
                        clipPath: 'polygon(0% 0%, 0% 100%, 15% 0%)', // Adjusted clipPath syntax
                        width: '75%',
                        height: '80%',
                        position: 'absolute',
                        top: '-8px',
                        left: 0,
                    }}></div>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '110px' }}>
                        <div className='header-logo' >
                            {/* <img src={Sohamlogo} className={selecteduitable === "SOHAM" ? "logo2":"logo1"} 
                             style={{ height: '153px', paddingBottom: '32px' }}
                              /> */}
                            <img
                                src={selecteduitable === "SOHAM" ? logo : Sohamlogo}
                                className={`logo ${selecteduitable === "SOHAM" ? "logo2" : "logo1"}`}
                                style={{ width: selecteduitable === "SOHAM" ? "307px" : "210px", height: selecteduitable === "SOHAM" ? "59px" : "69px", paddingBottom: selecteduitable === "SOHAM" ? "0px" : "-11px" }}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: '10px', marginLeft: '32px' }}>
                        <Text>
                            {/* <EnvironmentOutlined style={{ color: '#6f7755', verticalAlign: 'middle' }} /> */}
                            📍 1330, Plot No.190, Jivhala Colony, Kolhapur - 416 010
                        </Text>
                    </div>
                </Col>
                {/* Company Name and Contact Info Section */}
                <Col xs={8} sm={8} md={6}>
                    <Typography.Title level={3} style={{ color: 'rgb(229 63 68 / 84%)', marginBottom: 0, marginLeft: '-22px' }}>
                        Soham Scientific
                    </Typography.Title>
                    <Space direction="vertical" size="small" style={{ color: '#333', rowGap: '0px', marginLeft: '-22px' }}>
                        {/* <Text> */}
                        {/* <EnvironmentOutlined style={{ color: '#6f7755', verticalAlign: 'middle' }} /> */}
                        {/* 📍 1330, Plot No.190, Jivhala Colony, Kolhapur - 416 010 */}
                        {/* </Text> */}
                        <Text>
                            {/* <PhoneOutlined rotate={115} style={{ color: '#6f7755', verticalAlign: 'middle' }} /> */}
                            📞 9730283030 / 8855928382,
                            {/* <WhatsAppOutlined style={{ color: '#6f7755', verticalAlign: 'middle' }} /> 8686648282, 9922280555 */}
                        </Text>
                        <Text>
                            {/* <PhoneOutlined rotate={115} style={{ color: '#6f7755', verticalAlign: 'middle' }} />  */}
                            {/* <MailOutlined style={{ color: '#6f7755', verticalAlign: 'middle' }} />  */}
                            ✉️ sohamsci2005@yahoo.com, <br />
                            <strong>GST :</strong> 27CHIPP7362K1Z0
                        </Text>
                    </Space>
                    <div style={{
                        background: '#a6a6c0',
                        clipPath: 'polygon(100% 100%, -7% 297%, 110% 0px)', // Adjusted clipPath syntax
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}></div>
                </Col>
            </Row>
            {/* QUOTATION  */}
            <div style={{ marginTop: '15px', width: `${width}%` }}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Typography.Title level={4} style={{
                        textAlign: 'center', backgroundColor: 'rgb(166 166 192)', // rgb(185 185 214)
                        color: 'white', marginTop: '-11px', width: '35%'
                    }}>{title ? title : "QUOTATION"} </Typography.Title>
                </div>

                <Row gutter={16} style={{ marginBottom: '12px' }}>
                    <Col span={24} style={{ marginTop: '0px', marginBottom: '12px' }}>
                        <Typography.Text>
                            To,<br /> <b>{order.fullName}</b><br />
                        </Typography.Text>
                        <Typography.Text >{order.addressInfo}</Typography.Text>
                    </Col>
                    {/* 
                    <Col xs={24} sm={8} md={8} lg={6}>
                        <Typography.Text strong>Dealer Ref. No :</Typography.Text>
                        <Typography.Text> #12547</Typography.Text>
                    </Col> */}

                    <Col xs={24} sm={6} md={6} lg={6}>
                        <Typography.Text strong>{title ? 'Order No :' : 'Enquiry No :'  } </Typography.Text>
                        <Typography.Text>{order.enquiryNo}</Typography.Text>
                    </Col>

                    <Col xs={24} sm={6} md={8} lg={8}>
                        <Typography.Text strong>Mobile No : </Typography.Text>
                        <Typography.Text>{order.enquiryMobileNo}</Typography.Text>
                    </Col>

                    <Col xs={24} sm={6} md={4} lg={4}>

                    </Col>
                    <Col xs={24} sm={6} md={6} lg={6}>


                        <Typography.Text strong>Quotation No: </Typography.Text>
                        <Typography.Text strong>UBC-QU-</Typography.Text>
                        <Typography.Text strong>{order.id}</Typography.Text></Col>
                    <Col xs={24} sm={6} md={6} lg={6}>
                        <Typography.Text strong>{title ? 'Order Date :' : 'Enquiry Date  :'  } </Typography.Text>
                        <Typography.Text>{order.enquiryDate && order.enquiryDate > 0 ?
                            new Date(order.enquiryDate).toLocaleDateString('en-GB')
                            : ""} </Typography.Text>

                    </Col>

                    <Col xs={24} sm={6} md={8} lg={8}>
                        <Typography.Text strong>Email : </Typography.Text>
                        <Typography.Text>{order.enquiryEmail}</Typography.Text>

                    </Col>

                    <Col xs={24} sm={6} md={4} lg={4}>

                    </Col>

                    <Col xs={24} sm={6} md={6} lg={6}>
                        <Typography.Text strong>Quotation Date : </Typography.Text>
                        <Typography.Text> {order.orderDate && order.orderDate > 0 ?
                            new Date(order.orderDate).toLocaleDateString('en-GB')
                            : ""} </Typography.Text>
                    </Col>
                </Row>
            </div>
        </>
    )
};

export default SohamHeader;
