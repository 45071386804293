import React, { useState } from 'react';
import { Row, Col, Typography, Space, Input, Divider } from 'antd';
import { EnvironmentOutlined, PhoneOutlined, WhatsAppOutlined, MailOutlined } from '@ant-design/icons';
import logo from "../../components/reusable/logo-chemical.png";
import Sohamlogo from "../../components/reusable/SOHAM_LOGO.png";

interface UniqueBiologicalHeaderProps {
    width: number; // Define the type for width as number,
    selecteduitable: any,
    order: any,
    title?:any
}

const { Text } = Typography;

const UniqueBiologicalHeader: React.FC<UniqueBiologicalHeaderProps> = ({ order, width, selecteduitable,title }) => {
    return (
        <>
        {
            
            <Row
                style={{
                    background: 'white',
                    padding: '10px 0',
                    alignItems: 'center',
                    width: `${width}%`
                }}
            >
                <Col xs={18} sm={18} md={18}>
                    <Typography.Title level={3} style={{ color: '#6f7755', marginBottom: 0 }}>
                        UNIQUE BIOLOGICAL & CHEMICALS
                    </Typography.Title>
                    <Space direction="vertical" size="small" style={{ color: '#333', rowGap: '0px' }}>
                        <Text>
                            <EnvironmentOutlined style={{ color: '#6f7755', verticalAlign: 'middle' }} /> 726/A, ‘E’ Ward, Sai Plaza, 3rd Lane, Shahupuri, Kolhapur - 416001.
                        </Text>
                        <Text>
                            <PhoneOutlined rotate={115} style={{ color: '#6f7755', verticalAlign: 'middle' }} /> 0231-2661216, <WhatsAppOutlined style={{ color: '#6f7755', verticalAlign: 'middle' }} /> 8686648282, 9922280555
                        </Text>
                        <Text>
                            <PhoneOutlined rotate={115} style={{ color: '#6f7755', verticalAlign: 'middle' }} /> 9730283030, <MailOutlined style={{ color: '#6f7755', verticalAlign: 'middle' }} /> vinodunique@yahoo.com, <strong>GST :</strong> 27AMNPP2410Q1ZJ
                        </Text>
                    </Space>
                </Col>
                <Col xs={6} sm={6} md={6} style={{ textAlign: 'right' }}>
                    <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                        <div className='header-logo' >
                            {/* <img src={logo} className={selecteduitable === "SOHAM" ? "logo2":"logo1"} style={{ width: "307px", height: "69px" }} /> */}
                            <img
                                src={selecteduitable === "SOHAM" ? Sohamlogo : logo}
                                className={`logo ${selecteduitable === "SOHAM" ? "logo1" : "logo2"}`}
                                style={{ width: selecteduitable === "SOHAM" ? "307px" : "363px", height: selecteduitable === "SOHAM" ? "600px" : "91px" }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        }
            
            {/* QUOTATION  */}
            <div style={{ marginTop: '15px', width: `${width}%` }}>
                <Typography.Title level={4} style={{
                    textAlign: 'center',
                     backgroundColor: 'rgb(155 177 90 / 80%)',
                    color: 'white', marginTop: '-11px'
                }}>{title ? title : "QUOTATION"} </Typography.Title>

                <Row gutter={16} style={{ marginBottom: '12px' }}>
                    <Col span={24} style={{ marginTop: '0px', marginBottom: '12px' }}>
                        <Typography.Text>
                            To,<br /> <b>{order.fullName}</b><br />
                        </Typography.Text>
                        <Typography.Text >{order.addressInfo}</Typography.Text>
                    </Col>
                    {/* 
                    <Col xs={24} sm={8} md={8} lg={6}>
                        <Typography.Text strong>Dealer Ref. No :</Typography.Text>
                        <Typography.Text> #12547</Typography.Text>
                    </Col> */}

                    <Col xs={24} sm={6} md={6} lg={6}>
                        <Typography.Text strong>  {title ? 'Order No :' : 'Enquiry No :'  } </Typography.Text>
                        <Typography.Text>{order.enquiryNo}</Typography.Text>
                    </Col>

                    <Col xs={24} sm={6} md={8} lg={8}>
                        <Typography.Text strong>Mobile No : </Typography.Text>
                        <Typography.Text>{order.enquiryMobileNo}</Typography.Text>
                    </Col>

                    <Col xs={24} sm={6} md={4} lg={4}>

                    </Col>
                    <Col xs={24} sm={6} md={6} lg={6}>


                        <Typography.Text strong>Quotation No: </Typography.Text>
                        <Typography.Text strong>UBC-QU-</Typography.Text>
                        <Typography.Text strong>{order.id}</Typography.Text></Col>
                    <Col xs={24} sm={6} md={6} lg={6}>
                        <Typography.Text strong>{title ? 'Order Date :' : 'Enquiry Date  :'  } </Typography.Text>
                        <Typography.Text>{order.enquiryDate && order.enquiryDate > 0 ?
                            new Date(order.enquiryDate).toLocaleDateString('en-GB')
                            : ""} </Typography.Text>

                    </Col>

                    <Col xs={24} sm={6} md={8} lg={8}>
                        <Typography.Text strong>Email : </Typography.Text>
                        <Typography.Text>{order.enquiryEmail}</Typography.Text>

                    </Col>

                    <Col xs={24} sm={6} md={4} lg={4}>

                    </Col>

                    <Col xs={24} sm={6} md={6} lg={6}>
                        <Typography.Text strong>Quotation Date : </Typography.Text>
                        <Typography.Text> {order.orderDate && order.orderDate > 0 ?
                            new Date(order.orderDate).toLocaleDateString('en-GB')
                            : ""} </Typography.Text>
                    </Col>
                </Row>
            </div>
        </>
    )
};

export default UniqueBiologicalHeader;
