import React, { useState, useEffect, useCallback } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, InputLabel,
  TableRow, Paper, Button, TextField, Select, MenuItem, FormControl, IconButton
} from "@mui/material";
import * as XLSX from "xlsx";
import {
  get_units, get_companies, get_gst_rates, api_product_import,
  get_categories, get_sub_categories
} from "../services/product";
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { IMG_BASE_URL, insertProductAttriCount, defaultSubCategory } from "../../../../constants";
import { produce } from "immer";

const ProductImport = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([{
    "id": defaultSubCategory,
    name: "None"
  }]);
  const [companyId, setCompanyId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [initialLoading, setInitialLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [baseUnits, setBaseUnits] = useState([]);
  const [gstRates, setGstRates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [companyIdErrorMessage, setCompanyIdErrorMessage] = useState("");
  const [duplicates, setDuplicate] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const fetch_units = async () => {
    try {
      const response: any = await get_units();
      setBaseUnits(response.data.data)
    } catch (error) {
    }
  }
  const fetch_companies = async () => {
    try {
      const response: any = await get_companies();
      setCompanies(response.data.data)
    } catch (error) {
    }
  }
  const fetchGstRates = async () => {
    try {
      const response: any = await get_gst_rates();
      setGstRates(response.data?.data)
    } catch (error) {

    }
  }
  useEffect(() => {
    fetch_units();
    fetch_companies();
    fetch_categories();
    fetchGstRates();
  }, []);

  const fetch_categories = async () => {
    try {
      const response: any = await get_categories();
      setCategories(response.data.data)
    } catch (error) {
    }
  }
  const fetch_sub_categories = async (subCategoryId: number) => {
    try {
      const response: any = await get_sub_categories(subCategoryId);
      setSubCategories(response.data.data)
    } catch (error) {
    }
  }


  /*  function transformData(data:any) {
     const result:any = [];
 
     data.forEach((item:any) => {
         const baseName = item.name
         //  item.name && item.name.length <= 2 ? item.name :
         //   item.name.split(" ").slice(0, -2).join(" ");
         // Use companyProductCode as the grouping key
         const baseCode = item.companyProductCode;
 
         // Check if the baseCode already exists in result array
         const existingGroup = result.find((group: any) => group.companyProductCode === baseCode 
        );
 
         const match = item.unit.match(/^(\d+)\s*(\w+)$/);
         if (match) {
             // Extract dynamic quantity and unit
             item.qty = parseInt(match[1]);  // quantity
             item.unitName = match[2];       // unit name
         }else{
             item.unitName= ""
         }
         let samplePrice =""+item.price+"";
         samplePrice = samplePrice && (samplePrice) !="" ? samplePrice.replace(/\s/g, '') : "0";
         let price = item.price ? Number(samplePrice) : 0;
         let discount = item.discount ? item.discount : 0;
         let unitdata:any = baseUnits.find((e:any)=> e.unit_short_name == item.unitName.toLowerCase());
         const attributesObj = {
             companyProductCode: item.companyProductCode,
             unit: item.unit,
             qty: item.qty,
             unitName: item.unitName,
             price:price,
             discount:discount,
             unitId:unitdata && unitdata.id ? unitdata.id : "",
             gst_percentage:item.gst_percentage ? item.gst_percentage : 0,
             finalPrice:(price - (price * (discount/100))).toFixed(2),
             landingPrice: item.landingPrice,
             images:[],
             companyId:companyId
 
         };
 
         if (existingGroup) {
             // If the base name exists, push the new attributes into the existing attributes array
             existingGroup.attributes.push(attributesObj);
         } else {
             // If it doesn't exist, create a new object and push it to the result
             result.push({
                 name: baseName,
                 companyProductCode:baseCode,
                 attributes: [attributesObj]
             });
         }
     });
 
     return result;
 } */

  function transformData(data: any[]) {
    const result: any[] = [];
    const groupMap = new Map<string, any>(); // Using Map for faster lookups with mixed keys

    // Create a unitMap to avoid repeated `find` calls
    const unitMap = new Map<string, any>();
    baseUnits.forEach((unit: any) => {
      unitMap.set(unit.unit_short_name.toLowerCase(), unit);
    });

    data.forEach((item: any) => {
      const baseName = item.name;
      const baseCode = item.companyProductCode;

      // Check if baseCode already exists in the map
      let existingGroup = groupMap.get(baseCode);

      // Extract quantity and unit from item.unit if possible
      const match = item.unit.match(/^(\d+)\s*(\w+)$/);
      if (match) {
        item.qty = parseInt(match[1], 10);
        item.unitName = match[2];
      } else {
        item.unitName = "";
      }

      // Handle price parsing and discount calculations
      let samplePrice = item.price ? String(item.price).replace(/\s/g, '') : "0";
      let price = Number(samplePrice);
      let discount = item.discount || 0;

      // Retrieve unit data from unitMap
      const unitData = unitMap.get(item.unitName.toLowerCase());

      const attributesObj = {
        companyProductCode: item.companyProductCode,
        unit: item.unit,
        qty: item.qty,
        unitName: item.unitName,
        price: price,
        discount: discount,
        unitId: unitData?.id || "",
        gst_percentage: item.gst_percentage || 0,
        finalPrice: (price - (price * (discount / 100))).toFixed(2),
        landingPrice: item.landingPrice,
        productUnitCode: item.productUnitCode,
        images: [],
        companyId: companyId
      };

      if (existingGroup) {
        // Append attributesObj to the existing group’s attributes array
        existingGroup.attributes.push(attributesObj);
      } else {
        // Create new group and add it to the result array and map
        const newGroup = {
          name: baseName,
          companyProductCode: baseCode,
          attributes: [attributesObj]
        };
        result.push(newGroup);
        groupMap.set(baseCode, newGroup); // Store in map for quick future access
      }
    });

    return result;
  }



  // Handle file upload
  const handleFileUpload = (event: any) => {
    setInitialLoading(true);
    // Expected column names for validation
    const expectedColumns = ["name", "companyProductCode", "unit", "price", "discount",
      "gst_percentage", "landingPrice", "productUnitCode"];
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
        // Validate column names
        const headerRow: any = worksheet[0]; // Get the header row
        const isValidHeader = expectedColumns.every((col: any, index: any) => headerRow[index] === col);

        if (!isValidHeader) {

          setInitialLoading(false);
          setErrorMessage("Invalid file format. Please upload a file with the correct column names.");
          return; // Stop further processing if the column names don't match
        } else {
          setErrorMessage("");
        }
        let isValidData = false;
        // Convert rows
        const parsedRows: any = worksheet.slice(1).filter((e: any) => e[0] && e[0] != '').map((row: any) => {
          if (!row[0] || !row[1] || !(row[3] && row[3] > 0) || !row[2]) {
            isValidData = true;
          }
          return {
            name: row[0],
            companyProductCode: row[1],
            unit: row[2],
            qty: 1,
            price: row[3],
            discount: row[4],
            gst_percentage: row[5],
            landingPrice: row[6],
            productUnitCode: row[7],
          }
        });
        if (isValidData) {
          setInitialLoading(false);
          setErrorMessage("Invalid file data. Please upload a file with the correct values of name, companyProductCode, unit ,price.");
          return;
        }
        let newData: any = transformData(parsedRows);
        setRows([...newData]);
        setInitialLoading(false)
      };
      reader.readAsArrayBuffer(file);
    }
  };

  //   const handleInputChange = (index: number, index2: number, field: string, value: any, optionName?:any) => {
  //     const updatedRows:any = [...rows];
  //     const selectedAttribute:any = updatedRows[index].attributes[index2];
  //     if (field === 'unitId') {
  //         selectedAttribute.unitId = value ? value : "";
  //         selectedAttribute.unitName = optionName ? optionName : "";

  //     }
  //     selectedAttribute[field] = value;

  //     if (field === 'price' || field === 'discount') {
  //         const price = selectedAttribute.price || 0;
  //         const discount = selectedAttribute.discount || 0;
  //         selectedAttribute.finalPrice = (price - (price * (discount / 100))).toFixed(2);
  //     }

  //     setRows(updatedRows);
  // };
  // const handleInputChange = useCallback((index:any, index2:any, field:any, value:any, optionName?:any) => {
  //   setRows((prevRows:any) => {
  //       const updatedRows = [...prevRows];
  //       const selectedAttribute = updatedRows[index].attributes[index2];

  //       if (field === 'unitId') {
  //           selectedAttribute.unitId = value || "";
  //           selectedAttribute.unitName = optionName || "";
  //       }
  //       selectedAttribute[field] = value;

  //       if (field === 'price' || field === 'discount') {
  //           const price = selectedAttribute.price || 0;
  //           const discount = selectedAttribute.discount || 0;
  //           selectedAttribute.finalPrice = (price - (price * (discount / 100))).toFixed(2);
  //       }

  //       return updatedRows;
  //   });
  // }, []);
  const handleInputChange = useCallback((index: number, index2: number, field: string, value: any, optionName?: string) => {
    setRows((prevRows: any) =>
      produce(prevRows, (draft: any) => {
        const selectedAttribute = draft[index]?.attributes?.[index2];

        if (!selectedAttribute) return;

        // Update unitId and unitName if the field is 'unitId'
        if (field === 'unitId') {
          selectedAttribute.unitId = value || "";
          selectedAttribute.unitName = optionName || "";
        } else {
          selectedAttribute[field] = value;
        }

        // Only update finalPrice if field is 'price' or 'discount'
        if (field === 'price' || field === 'discount') {
          const price = parseFloat(selectedAttribute.price) || 0;
          const discount = parseFloat(selectedAttribute.discount) || 0;
          selectedAttribute.finalPrice = ((price - (price * (discount / 100))).toFixed(2));
        }
      })
    );
  }, []);
  const handleDeleteAttribute = useCallback((index: number, index2: number) => {
    setRows((prevRows: any) =>
      produce(prevRows, (draft: any) => {
        // Ensure that the row and attribute exist
        const selectedAttributes = draft[index]?.attributes;

        if (selectedAttributes) {
          // Log for debugging

          if (index2 >= 0 && index2 < selectedAttributes.length) {
            // Remove the attribute at index2
            selectedAttributes.splice(index2, 1);
          } else {
          }
        } else {
        }
      })
    );
  }, []);

  const saveData = async () => {
    if (companyId && Number(companyId) > 0 &&
      categoryId && Number(categoryId) > 0 &&
      subCategoryId && Number(subCategoryId) > 0 &&
      rows && rows.length > 0) {
      try {
        const seenAttributes = new Map(); // Map to store unique attribute keys
        const duplicateIndices: any = []; // To store indices of duplicates in the required format

        // Iterate through each product group
        rows.forEach((productGroup: any, mainIndex: any) => {
          // Iterate through each attribute in the group
          productGroup.attributes.forEach((attribute: any, attributeIndex: any) => {
            // Create a unique key based on companyProductCode, qty, companyId, and unitId
            const attributeKey = `${attribute.companyProductCode}-${attribute.qty}-${attribute.companyId}-${attribute.unitId}`;
            // Check if the attributeKey has been seen before or if the price is 0 or empty
            const priceIsInvalid = !(attribute.price > 0);
            const unitIdIsInvalid = !(attribute.unitId > 0);

            // Check if the attributeKey has been seen before
            if (seenAttributes.has(attributeKey) || priceIsInvalid || unitIdIsInvalid) {
              // If a duplicate is found, store the indices in the required format
              duplicateIndices.push({
                mainIndex: mainIndex,
                attributeIndex: attributeIndex
              });
            } else {
              // Otherwise, store the key and the current indices
              seenAttributes.set(attributeKey, { mainIndex, attributeIndex });
            }
          });
        });

        setDuplicate([...duplicateIndices]);
        if (duplicateIndices && duplicateIndices.length === 0) {
          setLoading(true);
          setErrorMessage("")
          let chunkedProductArray = Array.from(
            { length: Math.ceil(rows.length / insertProductAttriCount) },
            (_, i) => rows.slice(i * insertProductAttriCount, i * insertProductAttriCount + insertProductAttriCount)
          );
          for (const data of chunkedProductArray) {
            let payload = {
              categoryId: categoryId,
              subCategoryId: subCategoryId,
              importData: data
            };
            await api_product_import(payload)
          }
          // let payload = {
          //   importData :rows
          //  }
          //  await api_product_import(payload)
          setLoading(false)
          navigate("/admin/product_list")

        } else {
          setErrorMessage("Please avoid adding duplicate products, and ensure that the price is not empty or zero.")
        }
      } catch (error) {

      }
    } else {
      setCompanyIdErrorMessage("Please select the company, category, sub-category.");
    }

  }
  // Function to check if a given pair exists in duplicateIndices
  function isDuplicate(mainIndex: any, attributeIndex: any) {
    return duplicates.some(
      (duplicate: any) => duplicate.mainIndex === mainIndex && duplicate.attributeIndex === attributeIndex
    );
  }

  const onChangeCategoryId = (e: any) => {
    setCategoryId(e.target.value)
    setSubCategoryId('');
    fetch_sub_categories(Number(e.target.value))
  }
  const onChangeSubCategoryId = (e: any) => {
    setSubCategoryId(e.target.value)
  }

  return (
    <div style={{
      backgroundColor: 'rgb(228 228 228)',
      padding: '20px'
    }}>
      <h4>Import Products

        <a href={IMG_BASE_URL + "sampleProductImport.xlsx"}><label style={{
          fontSize: "13px",
          color: "#199bff",
          textDecorationLine: "underline",
          marginLeft: "10px",
          "cursor": "pointer"
        }}>Sample Example File</label></a>
        <label style={{ color: "red", fontSize: "12px", marginLeft: "10px" }}>Upload an Excel file containing only 200 rows to ensure optimal performance speed. </label>
      </h4>
      <FormControl style={{ width: "150px", marginRight: "20px" }} size="small">
        <InputLabel>
          Company
        </InputLabel>
        <Select
          size="small"
          name="company"
          value={companyId}
          disabled={(companyId && Number(companyId) > 0) ? true : false}
          label="Company"
          onChange={(e) => {
            setCompanyId(e.target.value)
            const selectedCompany: any = companies.find((company: any) => company.id === e.target.value);
            setCompanyName(selectedCompany.name);
            setCompanyIdErrorMessage("");
          }}
        >
          {companies.map((e: any, indexG: any) => (
            <MenuItem value={e.id} key={"company" + indexG}>{e.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ width: "200px", marginRight: "10px" }} size="small">
        <InputLabel>
          Category <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <Select
          name="categoryId"
          label="Category *"
          value={categoryId}
          disabled={loading}
          onChange={onChangeCategoryId}
          fullWidth
        >{
            categories && categories.length > 0 && (
              categories.map((e: any, index: number) => {
                return (
                  <MenuItem value={e.id} key={"category" + index}>{e.name}</MenuItem>
                )
              })
            )
          }
        </Select>
      </FormControl>
      <FormControl style={{ width: "200px", marginRight: "10px" }} size="small">
        <InputLabel>
          Sub-Category <span style={{ color: 'red' }}>*</span>
        </InputLabel>
        <Select
          name="subCategoryId"
          label="Sub-Category *"
          disabled={loading}
          value={subCategoryId}
          onChange={onChangeSubCategoryId}
          fullWidth
        >
          {
            subCategories && subCategories.length > 0 && (
              subCategories.map((e: any, index: number) => {
                return (
                  <MenuItem value={e.id} key={"subCategories" + index}>{e.name}</MenuItem>
                )
              })
            )
          }

        </Select>
      </FormControl>

      {companyId && Number(companyId) > 0 &&
        categoryId && Number(categoryId) > 0 &&
        subCategoryId && Number(subCategoryId) > 0 &&
        <Button variant="contained" component="label"

          disabled={loading || (rows && rows.length > 0)}>
          Upload File
          <input type="file" hidden onChange={handleFileUpload} accept=".xlsx, .xls" />
        </Button>
      }
      {
        rows && rows.length > 0 && <Button
          onClick={() => {
            saveData()
          }}
          disabled={loading}
          variant="contained" component="label"
          style={{ marginLeft: "10px" }}> {loading && <CircularProgress size="20px" style={{ marginRight: "10px" }} />}  Save
        </Button>
      }
      {loading && <label style={{ marginLeft: "10px" }}>Uploading data... please just wait few minutes and don't page refresh it....</label>}
      {companyIdErrorMessage && <><br />
        <label style={{ color: "red" }}>{companyIdErrorMessage}</label></>}
      {errorMessage && <><br />
        <label style={{ color: "red" }}>{errorMessage}</label></>}
      {
        initialLoading && <div><br />
          Loading data.... It will take 30 sec to 1 min so please don't refresh the page
        </div>
      }
      {rows.length > 0 && (
        <div style={{ width: "100%", height: "600px", overflowY: "auto" }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Unit Code</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Landing Price</TableCell>
                  <TableCell>Discount</TableCell>
                  <TableCell>Final Price</TableCell>
                  <TableCell>GST Percentage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: any, index: any) =>
                  row.attributes.map((attribute: any, index2: any) => (
                    <TableRow key={index2} style={isDuplicate(index, index2) ? {
                      backgroundColor: "#bbffcf"
                    } : {}}>
                      <TableCell>{row.name}
                        {
                          isDuplicate(index, index2) && <ErrorIcon style={{ color: "red" }} />
                        }

                      </TableCell>
                      <TableCell>{attribute.companyProductCode}</TableCell>
                      <TableCell>{attribute.productUnitCode}</TableCell>
                      <TableCell>
                        <TextField
                          size="small"
                          type="number"
                          value={attribute.qty}
                          onChange={(e) => handleInputChange(index, index2, "qty", e.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <Select
                          size="small"
                          value={attribute.unitId}
                          onChange={(e) => handleInputChange(index, index2, "unitId", e.target.value, e.target.name)}
                        >
                          {baseUnits.map((unit: any) => (
                            <MenuItem key={unit.id} value={unit.id}>
                              {unit.unit_short_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <TextField
                          size="small"
                          type="number"
                          value={attribute.price}
                          onChange={(e) => handleInputChange(index, index2, "price", e.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          size="small"
                          type="number"
                          value={attribute.landingPrice}
                          onChange={(e) => handleInputChange(index, index2, "landingPrice", e.target.value)}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          size="small"
                          type="number"
                          value={attribute.discount}
                          onChange={(e) => handleInputChange(index, index2, "discount", e.target.value)}
                        />
                      </TableCell>
                      <TableCell>{attribute.finalPrice}</TableCell>
                      <TableCell>
                        <Select
                          size="small"
                          value={attribute.gst_percentage}
                          onChange={(e) => handleInputChange(index, index2, "gst_percentage", e.target.value)}
                        >
                          {gstRates.map((e: any, indexG: any) => (
                            <MenuItem value={e.rate} key={index + "gst" + indexG}>{e.rate}%
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <IconButton color="error" aria-label="delete"
                          onClick={(e) => handleDeleteAttribute(index, index2)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default ProductImport;
