import React from 'react';
import PendingProducts from "../../components/admin/purchase_order/components/PendingProducts";
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
const PendingProductsUI = () => {
  return (
    <div>
   <PersistentDrawerLeft>
      <PendingProducts/>
     </PersistentDrawerLeft>
    </div>
  );
};

export default PendingProductsUI;