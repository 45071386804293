import React, { useEffect } from 'react';
import CreateDeliveryChallan from "../../components/admin/purchase_order/components/DeliveryChallan/CreateDeliveryChallan";
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';

const DeliveryChallan = () => {
  const navigate = useNavigate();
  const token: string = localStorage.getItem("token") ? "" + localStorage.getItem("token") + "" : "";
  const decoded: { id: number; role: string; } = jwtDecode(token);
  useEffect(() => {
    if (token) {
      const decoded: { id: number; role: string } = jwtDecode(token);
      if (decoded.role !== "ADMIN") {
        // navigate('/admin/quotation/create');
      }
    }
  }, [token, navigate]);
  return (
    <div>
      <PersistentDrawerLeft>
        <div style={{ backgroundColor: 'rgb(190 192 191 / 37%)', height: '100vh', marginTop:'10px'}} >
          <CreateDeliveryChallan />
        </div>
      </PersistentDrawerLeft>
    </div>
  );
};

export default DeliveryChallan;