import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { delivery_challan_pending_products } from "../services/order";
import { styled } from "@mui/system";
import { Link, useParams } from "react-router-dom";
import * as XLSX from "xlsx";

// Styled components for table header
const StyledTableHead = styled(TableHead)`
  background-color: #dfffd6; /* Light green */
`;

const SmallTableCell = styled(TableCell)`
  font-size: 12px; /* Small size */
`;

const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const PurchaseOrderTable: React.FC = () => {
  const { orderId } = useParams();
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      if (orderId) {
        try {
          const response: any = await delivery_challan_pending_products(orderId);
          setData(
            response?.data?.quotationProducts
              ? response?.data?.quotationProducts
              : []
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [orderId]);

  // Export to Excel
  const exportToExcel = () => {
    // Map data to include only displayed columns
    const filteredData = data.map((row) => ({
      "Product Name": row.productName,
      Category: row.categoryName,
      Company: row.companyName,
      Pack: row.pack,
      Quantity: row.quotationQty,
      "Total Price": row.totalPrice,
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Pending Products");
    XLSX.writeFile(workbook, `Pending_Products_${orderId}.xlsx`);
  };

  return (
    <div
      style={{
        backgroundImage: "linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%)",
        borderRadius: "20px",
        padding: "20px",
        marginTop: "10px",
      }}
    >
      {/* Header Section */}
      <HeaderContainer>
        <Typography variant="h6" component="h2">
          Pending Products in Purchase Order No ( PO{orderId} )
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={exportToExcel}
            style={{ marginRight: "10px" }}
          >
            Export to Excel
          </Button>
          <Link to={"/admin/purchase_order_list"}>
            <Button variant="contained" color="primary">
              Back
            </Button>
          </Link>
        </Box>
      </HeaderContainer>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="purchase order table">
          <StyledTableHead>
            <TableRow>
              <SmallTableCell>Product Name</SmallTableCell>
              <SmallTableCell>Category</SmallTableCell>
              <SmallTableCell>Company</SmallTableCell>
              <SmallTableCell>Pack</SmallTableCell>
              <SmallTableCell>Quantity</SmallTableCell>
              <SmallTableCell>Total Price</SmallTableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <SmallTableCell>{row.productName}</SmallTableCell>
                <SmallTableCell>{row.categoryName}</SmallTableCell>
                <SmallTableCell>{row.companyName}</SmallTableCell>
                <SmallTableCell>{row.pack}</SmallTableCell>
                <SmallTableCell>{row.quotationQty}</SmallTableCell>
                <SmallTableCell>{row.totalPrice}</SmallTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PurchaseOrderTable;
