import React,{useEffect} from 'react';
import { Route, Routes,Navigate, useNavigate } from 'react-router-dom';
import Login from "./login";
import Dashboard from "./admin/dashboard";
// import DashboardWithGst from "./admin/dashboard_with_gst";
import Product from './admin/product';
import AdminProductList from "./admin/productList";
import AdminProductEdit from "./admin/productEdit";
import AdminOrderCreate from "./admin/order";
import AdminOrderList from "./admin/orderList";
import AdminOrderDetail from "./admin/orderDetail";
import ProductImport from './admin/productImport';
import AdminOrderEdit from "./admin/orderEdit";
import Footer from '../components/reusable/admin/Footer';
import Department_listTable from './admin/Department_list';
import DepartmentADD from './admin/Department';
import DepartmentEdit from './admin/DepartmentEdit';
import UserAdd from './admin/addUser';
import UserList from './admin/userList';
import UserEdit from './admin/userEdit';
import DaliveryChallanList from "./admin/daliveryChallanList";
import DeliveryChallanCreate from "./admin/deliveryChallanCreate";
import DeliveryChallanUpdate from "./admin/deliveryChallanUpdate";
import DeliveryChallanDetail from './admin/deliveryChallanDetail';
import PurchaseOrderCreate from "./admin/purchaseOrder";
import PurchaseOrderList from "./admin/purchaseOrderList";
import PurchaseOrderDetail from "./admin/purchaseOrderDetail";
import PurchaseOrderEdit from "./admin/purchaseOrderEdit";
import PendingProductsUI from "./admin/pendingProductsforChallan";
const AppRoutes = () => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "F1") {
        event.preventDefault();
        navigate("/admin");
      }
      else if (event.key === "F2") {
        event.preventDefault();
        navigate("/admin/purchase_order_list");
      }
      else if (event.key === "F3") {
        event.preventDefault();
        navigate("/admin/quotation_list");
      }
      else if (event.key === "F4") {
        event.preventDefault();
        navigate("/admin/quotation/create");
      }
      else if (event.key === "F5") {
        event.preventDefault();
        navigate("/admin/product_list");
      }
      else if (event.key === "F6") {
        event.preventDefault();
        navigate("/admin/department_list");
      }
      else if (event.key === "F7") {
        event.preventDefault();
        navigate("/admin/User_list");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate]);
  return (
    <>
    <Routes>
    <Route path="/" element={token ? <AdminOrderList /> : <Login />} />
      <Route path="/" element={<Login />} />
      {/* <Route path="/product_list" element={<ProductList/>} /> */}
      {/* <Route path="/product_detail/:id" element={<ProductDetail/>} /> */}
      {/* <Route path="/cart" element={<Cart/>} /> */}
      <Route path="/login" element={<Login/>} />
      {/* <Route path="/register" element={<Register/>} /> */}
      <Route path="/admin" element={token ? <Dashboard /> : <Navigate to="/login" />} />
      {/* <Route path="/admin/dashboard_with_gst" element={<DashboardWithGst/>} /> */}
      <Route path="/admin/product" element={ token ? <Product /> : <Navigate to="/login" />} />
      <Route path="/admin/product_list" element={ token ? <AdminProductList /> : <Navigate to="/login" />} />
      <Route path="/admin/product_edit/:productId" element={ token ? <AdminProductEdit /> : <Navigate to="/login" />} />
      <Route path="/admin/quotation/create" element={ token ? <AdminOrderCreate /> : <Navigate to="/login" />} />
      <Route path="/admin/quotation_list" element={ token ? <AdminOrderList /> : <Navigate to="/login" />} />
      <Route path="/admin/quotation_list/:startDateFilter/:endDateFilter" element={ token ? <AdminOrderList /> : <Navigate to="/login" />} />
      <Route path="/admin/quotation_edit/:orderId" element={ token ? <AdminOrderDetail /> : <Navigate to="/login" />} />
      <Route path="/admin/quotation_update/:orderId" element={ token ? <AdminOrderEdit /> : <Navigate to="/login" />} />
      <Route path="/admin/product_import" element={ token ? <ProductImport /> : <Navigate to="/login" />} />
      
      <Route path="/admin/department_list" element={token ? <Department_listTable /> : <Navigate to="/login" />} />
      <Route path="/admin/department" element={token ? <DepartmentADD /> : <Navigate to="/login" />} />
      <Route path="/admin/departmentUpdate/:departmentId" element={token ? <DepartmentEdit /> : <Navigate to="/login" />} />

      <Route path="/admin/User_list" element={token ? <UserList /> : <Navigate to="/login" />} />
      <Route path="/admin/User" element={token ? <UserAdd /> : <Navigate to="/login" />} />
      <Route path="/admin/userListUpdate/:usersId" element={token ? <UserEdit /> : <Navigate to="/login" />} />
      <Route path="/admin/delivery-challan/:purchaseOrderId" element={token ? <DaliveryChallanList /> : <Navigate to="/login" />} />
      <Route path="/admin/delivery-challan/create/:purchaseOrderId" element={token ? <DeliveryChallanCreate /> : <Navigate to="/login" />} />
      <Route path="/admin/delivery-challan/update/:purchaseOrderId/:deliveryChallanId" element={token ? <DeliveryChallanUpdate /> : <Navigate to="/login" />} />
      <Route path="/admin/delivery-challan/detail/:purchaseOrderId/:deliveryChallanId" element={token ? <DeliveryChallanDetail /> : <Navigate to="/login" />} />
      <Route path="/admin/purchase_order/create" element={ token ? <PurchaseOrderCreate /> : <Navigate to="/login" />} />
      <Route path="/admin/purchase_order_list" element={ token ? <PurchaseOrderList /> : <Navigate to="/login" />} />
      <Route path="/admin/purchase_order_list/:startDateFilter/:endDateFilter" element={ token ? <PurchaseOrderList /> : <Navigate to="/login" />} />
      <Route path="/admin/purchase_order_edit/:orderId" element={ token ? <PurchaseOrderEdit /> : <Navigate to="/login" />} />
      <Route path="/admin/purchase_order_detail/:orderId" element={ token ? <PurchaseOrderDetail /> : <Navigate to="/login" />} />
      <Route path="/admin/pending-products/:orderId" element={ token ? <PendingProductsUI /> : <Navigate to="/login" />} />
      
      </Routes>
    <br/>
    <Footer/>
    </>
  );
};

export default AppRoutes;