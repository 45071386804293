import React, { useEffect, useState } from "react";
import { getPurchase_Order } from "../services/dashboard";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Chip,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

// Styled components
const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: "#e8f5e9", // Light green background
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "#388e3c", // Dark green text color
  textAlign: "center",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f9f9f9", // Light grey for alternate rows
  },
}));

const CenteredCircularProgress = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
});

const PurchaseOrderDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const result: any = await getPurchase_Order();
    setOrders(result?.data ? result?.data : []);
    setLoading(false);
  };
  const redirectToDetail = (id: any) => {
    navigate('/admin/purchase_order_detail/' + id);

  }
  const redirectToPending = (id: any) => {
    navigate('/admin/pending-products/' + id);

  }
  const redirectToDeliveryChallan = (id: any) => {
    navigate('/admin/delivery-challan/' + id);

  }
  console.log("orders", orders);
  return (
    <div>
      {loading ? (
        <CenteredCircularProgress>
          <CircularProgress />
        </CenteredCircularProgress>
      ) : (
        <TableContainer component={Paper} elevation={3}>
          <Table size="small">
            <StyledTableHead>
              <TableRow>
                <StyledTableCell>ID</StyledTableCell>
                <StyledTableCell>PO Created Date</StyledTableCell>
                <StyledTableCell>Firm</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Employee</StyledTableCell>
                <StyledTableCell>Customer Name</StyledTableCell>
                <StyledTableCell>Mobile No</StyledTableCell>
                <StyledTableCell>Order No </StyledTableCell>
                <StyledTableCell>Order Date</StyledTableCell>
                <StyledTableCell>Total Products</StyledTableCell>
                <StyledTableCell>Pending Products</StyledTableCell>
                <StyledTableCell>Delivery Challan Count</StyledTableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {orders.map((order) => (
                <StyledTableRow key={order.id} >
                  <TableCell align="center">{order.id}</TableCell>
                  <TableCell align="center">
                    {new Date(order.quotationDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">{order.firm}</TableCell>
                  <TableCell align="center">
                    <Chip label={order.status} color="primary" size="small" />
                  </TableCell>
                  <TableCell align="center">{order.employee}</TableCell>
                  <TableCell align="center">{order.fullName}</TableCell>
                  <TableCell align="center">{order.mobileNo}</TableCell>
                  <TableCell align="center">{order.enquiryNo}</TableCell>
                  <TableCell align="center"> {order.enquiryDate && order.enquiryDate > 0 ?
                    new Date(order.enquiryDate).toLocaleDateString('en-GB')
                    : ""}</TableCell>
                  <TableCell align="center">{order.total_product_count}</TableCell>
                  <TableCell align="center" style={{ cursor: "pointer" }}
                    onClick={() => redirectToPending(order.id)}>{order.pending_product_count}</TableCell>
                  <TableCell align="center" style={{ cursor: "pointer" }}
                    onClick={() => redirectToDeliveryChallan(order.id)}>{order.delivery_challan_count}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default PurchaseOrderDashboard;
