import React from 'react';
import { Row, Col, Typography, Space } from 'antd';
import { EnvironmentOutlined, PhoneOutlined, WhatsAppOutlined, MailOutlined } from '@ant-design/icons';
import logo from "../../components/reusable/logo-chemical.png";
import Sohamlogo from "../../components/reusable/SOHAM_LOGO.png";

interface DCDcsohamHeaderHeaderProps {
    width: number;
    selecteduitable: any;
    order: any;
}

const { Text, Title } = Typography;

const DcsohamHeader: React.FC<DCDcsohamHeaderHeaderProps> = ({ order, width, selecteduitable }) => {
    const logoStyle = {
        width: selecteduitable === "SOHAM" ? "299px" : "100%",
        height: selecteduitable === "SOHAM" ? "89px" : "91px",
    };

    const clipPathStyle = {
        clipPath: 'polygon(0 0, 0% 100%, 100% 100%)',
        backgroundColor: 'rgb(166, 166, 192)',
        height: "120px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const textStyle = {
        color: 'black',
        fontSize: '14px',
    };

    const borderStyle = {
        borderRight: '3px solid rgb(166, 166, 192)',
        marginTop: '10px'
    };

    const titleStyle = {
        display: 'inline-block',
        backgroundColor: 'rgb(166, 166, 192)',
        color: 'white',
        padding: '2px 16px',
        borderBottomRightRadius: '16px',
        borderBottomLeftRadius: '16px',
    };

    const placeholderStyle = {
        display: "block",
        width: "100%",
        height: "10px",
        borderBottom: "1px solid black",
    };
    const Adrresstyle = {
        paddingTop: '12px',
        paddingLeft: '20px'
    };
    const TitleHeadingstyle: React.CSSProperties = {
        textAlign: 'center',
        marginTop: '-28px'
    };
    const Rowmainstyle: React.CSSProperties = {
        background: 'rgb(244 244 244)',
        padding: '0px 0',
        marginTop: '20px',
        width: `${width}%`,
    };
    const mainDivstyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
    };

    return (
        <>
            <Row style={Rowmainstyle}>
                <Col xs={3} style={clipPathStyle}></Col>

                <Col xs={9} style={{ textAlign: 'right' }}>
                    <div
                        style={{
                            ...mainDivstyle,
                            ...borderStyle,
                        }}
                    >
                        <img
                            src={selecteduitable === "SOHAM" ? Sohamlogo : logo}
                            style={logoStyle}
                            alt="Logo"
                        />
                    </div>
                </Col>

                <Col xs={10} style={Adrresstyle}>
                    <Space direction="vertical" size="small">
                        <Text>
                            <EnvironmentOutlined style={textStyle} />
                            1330, Plot No.190, Jivhala Colony, Kolhapur - 416010
                        </Text>
                        <Text>
                            <PhoneOutlined rotate={115} style={textStyle} />
                            Mob.: 9730283030/8855928382 {' '}<WhatsAppOutlined style={textStyle} /> 8855928382
                        </Text>
                        <Text>
                            <MailOutlined style={textStyle} />{" "}
                            E-mail: sohamsci2005@yahoo.com, <strong>GST :</strong> 27CHIPP7362K1Z0
                        </Text>
                    </Space>
                </Col>

                <Col xs={2} style={{
                    ...clipPathStyle,
                    clipPath: 'polygon(100% 67%, 0 0, 100% 0)',
                }}></Col>
            </Row>

            <div style={{ width: `${width}%` }}>
                <div style={TitleHeadingstyle}>
                    <Title
                        level={4}
                        style={titleStyle}
                    >
                        DELIVERY CHALLAN
                    </Title>
                </div>

                <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
                    <Col xs={12} sm={18} md={18} lg={18} style={{ ...borderStyle, height: '110px' }}>
                        <Text>
                            <strong>To,</strong>
                            <br />
                            
                            {order.fullName ? 
                                <>
                                <b>
                                    {order.fullName}
                                </b> <br/>
                                <label>
                                    {order.addressInfo}
                                </label> <br/>
                                <label>
                                    {order.mobileNo}
                                </label> <br/>

                                </> : Array(3).fill(
                                
                                <>
                                    <b style={placeholderStyle}>
                                        { ""}
                                    </b><br />
                                </>
                            )}
                        </Text>
                    </Col>

                    {/* Right Side: D.C. No, Date, etc. */}
                                      <Col xs={12} sm={6} md={6} lg={6}>
                                          {/* First Row on the Right */}
                                          <Row gutter={[2, 2]}>
                                              <Col xs={24} sm={24}>
                                                  <Typography.Text strong>D.C. No:</Typography.Text>{" "}
                                                  <Typography.Text>{new Date(order.deliveryChallanDate).getFullYear()+"/00"+order.id+"" || "_____"}</Typography.Text>
                                              </Col>
                                              <Col xs={24} sm={24}>
                                                  <Typography.Text strong>Date:</Typography.Text>{" "}
                                                  <Typography.Text>
                                                      {order.deliveryChallanDate && order.deliveryChallanDate > 0
                                                          ? new Date(order.deliveryChallanDate).toLocaleDateString('en-GB')
                                                          : "_____/_____/_____"}
                                                  </Typography.Text>
                                              </Col>
                                              <Col xs={24} sm={24}>
                                                  <Typography.Text strong>Order No:</Typography.Text>{" "}
                                                  <Typography.Text>{order.orderNo || "_____"}</Typography.Text>
                                              </Col>
                                              <Col xs={24} sm={24}>
                                                  <Typography.Text strong>Order Date:</Typography.Text>{" "}
                                                  <Typography.Text>
                                                      {order.orderDate && order.orderDate > 0
                                                          ? new Date(order.orderDate).toLocaleDateString('en-GB')
                                                          : "_____/_____/_____"}
                                                  </Typography.Text>
                                              </Col>
                                              <Col xs={24} sm={24}>
                                                  <Typography.Text strong>Department:</Typography.Text>{" "}
                                                  <Typography.Text>{order.department || "_____"}</Typography.Text>
                                              </Col>
                                          </Row>
                                      </Col>
                </Row>
            </div>
        </>
    );
};

export default DcsohamHeader;
