import { Container } from '@mui/material';
import { Button } from 'antd';
import React, { useRef, useState, useEffect } from 'react'
import DcHeader from '../../../../reusable/DcHeader';
import DcsohamHeader from '../../../../reusable/DcsohamHeader';
import ReactToPrint from 'react-to-print';
import FirmSelector from '../../../../reusable/FirmSelector';
import DcTable from './DcTable';
import { useParams } from 'react-router-dom';
import { delivery_challanapi } from '../../services/order';
import { Link } from 'react-router-dom';

// Define the type for row data
interface RowData {
    srNo: number;
    product_name: string;
    qty: string | number;
    unit_name: string;
    company_name: string;
}
const DeliveryChallanDetail = () => {
    const { deliveryChallanId, purchaseOrderId } = useParams();
    const [selecteduitable, setSelecteduitablee] = useState<any>("UNIQUE FIRM");
    const printRef = useRef<HTMLDivElement>(null);
    const [showTotal, setShowTotal] = useState(true);
    const [rows, setRows] = useState<RowData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [deliveryChallan, setDeliveryChallan] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            if (deliveryChallanId) {
                try {
                    setLoading(true);
                    const response: any = await delivery_challanapi(deliveryChallanId);
                    console.log("API Response:", response.data.deliveryChallanProducts);
                    setRows(response.data.deliveryChallanProducts);
                    setDeliveryChallan(response?.data?.deliveryChallan?.length > 0 ?
                        response?.data?.deliveryChallan[0] : {})
                    if (response?.data?.deliveryChallan?.length > 0 && response?.data?.deliveryChallan[0].firm) {
                        setSelecteduitablee(response?.data?.deliveryChallan[0].firm === "UNIQUE" ? "UNIQUE FIRM" : "SOHAM FIRM")
                    }
                } catch (err) {
                    console.error("Error fetching data:", err);
                    setError("Failed to fetch data");
                } finally {
                    setLoading(false);
                }
            }

        };

        fetchData();
    }, [deliveryChallanId]);
    return (
        <>
            <div style={{
                backgroundColor: selecteduitable === "UNIQUE FIRM" ? '#e5f1c3' : 'rgb(228 228 228)',
                padding: '20px'
            }}>
                <Container>
                    <div>
                        <Link to={"/admin/delivery-challan/" + purchaseOrderId}>  <Button >Back</Button></Link>

                        <FirmSelector setSelecteduitablee={setSelecteduitablee} printRef={printRef} showTotal={showTotal}
                            setShowTotal={setShowTotal} deliveryChallan={deliveryChallan} />
                    </div>

                    <div ref={printRef}>
                        {selecteduitable === "SOHAM FIRM" ?
                            <DcsohamHeader
                                width={100}
                                selecteduitable="SOHAM"
                                order={deliveryChallan}
                            />
                            : <DcHeader
                                width={100}
                                selecteduitable="UNIQUE"
                                order={deliveryChallan}
                            />
                        }
                        <DcTable selecteduitable={selecteduitable} showTotal={showTotal}
                            rows={rows}
                            loading={loading}
                            error={error}
                        />
                    </div>

                </Container>
            </div>
        </>
    );
}

export default DeliveryChallanDetail