import React, { useState, useEffect } from "react";
import PersistentDrawerLeft from "../../../reusable/admin/Main";
import { FormDiv, ButtonCust } from "../styles/Product.style";
import { Form, Button, Table } from "antd";
import { Box, Typography } from "@mui/material";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { BUTTON_STYLE } from "../../../../constants/style";
import { department_list } from "../services/departmentapi"; // Import the API function
import "../styles/department.css"
const styletabletext = {
    fontSize: "14px",
};

interface Department {
    id: number;
    name: string;
}

const Department_list = () => {
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState<Department[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
  
    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                setLoading(true);
                const response: any = await department_list({});
                // const departmentData = Array.isArray(response.data) ? response.data : [];
                setDataSource(response.data.data);
            } catch (error) {
                // console.error("Error fetching department data:", error);
                setDataSource([]);
            } finally {
                setLoading(false);
            }
        };

        fetchDepartments();
    }, []);


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (text: string | number) => (
                <Typography style={styletabletext}>{text}</Typography>
            ),
        },
        {
            title: "Department Name",
            dataIndex: "name",
            key: "name",
            render: (text: any) => (
                <Typography style={styletabletext}>{text}</Typography>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (_: any, record: { id: number }) => (
                <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => navigate(`/admin/departmentUpdate/${record.id}`)}
                >
                    Edit
                </Button>
            ),
        },
    ];

    const handleCreate = () => {
        navigate(`/admin/department`);
    };

    return (
        <FormDiv >
            <Box
                sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2}}
            >
                <Typography variant="h6">Department List</Typography>
                <ButtonCust onClick={handleCreate} sx={BUTTON_STYLE}>
                    Add
                </ButtonCust>
            </Box>

            <Table
                dataSource={dataSource}
                columns={columns}
                bordered
                rowKey="id"
                pagination={{ pageSize: 10 }}
                size="small"
                className="tabledepartmentlistbg"
                style={{ border: "1px solid #ddd", borderRadius: "8px"}}
                loading={loading}
            />
        </FormDiv>
    );
};

export default Department_list;
