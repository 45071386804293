import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from "react-router-dom";
import "./header.css";
import { jwtDecode } from "jwt-decode";
import { HEADER_ICON_COLOR, HEADING_COLOR } from "../../../constants/style";
import { WEB_BASE_URL } from '../../../constants';
import Swal from 'sweetalert2';
import DashboardIcon from '@mui/icons-material/Dashboard';
import StoreIcon from '@mui/icons-material/Store';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupIcon from '@mui/icons-material/Group';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
    },
  ],
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
  background: "#23bda89e",
  zIndex:1000,
  color:"black"

}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  background: "#23bda89e",
  color:"black"
}));

export default function PersistentDrawerLeft({ children }: any) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const token: string = localStorage.getItem("token") ? "" + localStorage.getItem("token") + "" : "";
  const decoded: { id: number; role: string; } = jwtDecode(token);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  let menuList = decoded.role === "ADMIN" ? ['Dashboard', 'Department', 'User', 'Products', 'Quotation', 'Quotation List', 'Purchase Order', 'Logout'] :
    ['Department', 'User', 'Quotation', 'Quotation List',  'Purchase Order', 'Logout'];

  // const logout = () => {
  //   localStorage.removeItem("token");
  //   window.location.href = `${WEB_BASE_URL}login`;
  // }

  const logout = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to log out?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirmed, proceed with logout
        localStorage.removeItem('token');
        window.location.href = `${WEB_BASE_URL}login`;

        // Optional: show a success message after logout
        Swal.fire({
          title: 'Logged Out',
          text: 'You have been logged out successfully!',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });
      } else {
        // Optional: Show a message if the user cancels the logout
        Swal.fire({
          title: 'Cancelled',
          text: 'You are still logged in.',
          icon: 'info',
          timer: 1500,
          showConfirmButton: false,
        });
      }
    });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                mr: 2,
              },
              open && { display: 'none' },
            ]}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Devminds Software {
              localStorage.getItem("fullName") ? " - " + localStorage.getItem("fullName") : ""
            }
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: '#23bda89e',
            border: 'none',
            color:"black"
          },

        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader >
          <div style={{
                paddingLeft: "10px",
                display: "flex",
                width:"100%",
            justifyContent: "space-between"
          }}>
          <img src="/Logodev.png"
            style={{
              width: "138px",
              height: "32px",
              
            }} />
          <IconButton onClick={handleDrawerClose} size="small" >
            {theme.direction === 'ltr' ? <ChevronLeftIcon style={{color:"black"}} /> : <ChevronRightIcon style={{color:"black"}} />}
          </IconButton>
          </div>
         
        </DrawerHeader>
        <Divider />
        {/* <List>
          {menuList.map((text, index) => (
            <ListItem key={text} disablePadding>
              {
                text == 'Logout' ?
                  <ListItemButton onClick={logout}>
                    <ListItemIcon>
                      {index % 2 === 0 ? <InboxIcon style={{ color: HEADING_COLOR }} /> : <MailIcon style={{ color: HEADING_COLOR }} />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                  : <Link to={
                    text == 'Dashboard' ? "/admin" :
                      text == 'Products' ? "/admin/product_list" :
                        text == 'Quotation' ? "/admin/quotation/create" :
                          text == 'Department' ? "/admin/department_list" :
                            text == 'User' ? "/admin/User_list" :
                              text == 'Reports' ? "/admin/reports" :
                                text == "Quotation List" ? "/admin/quotation_list" :
                                  "/admin"} className='linktag'>
                    <ListItemButton>
                      <ListItemIcon>
                        {index % 2 === 0 ? <InboxIcon style={{ color: HEADING_COLOR }} /> : <MailIcon style={{ color: HEADING_COLOR }} />}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </Link>
              }


            </ListItem>
          ))}
        </List> */}

        <List>
          {menuList.map((text, index) => (
            <ListItem key={text} disablePadding>
              {text === 'Logout' ? (
                <ListItemButton onClick={logout}>
                  <ListItemIcon>
                    <ExitToAppIcon style={{ color: HEADER_ICON_COLOR }} />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              ) : (
                <Link
                  to={
                    text === 'Dashboard'
                      ? '/admin'
                      : text === 'Products'
                        ? '/admin/product_list'
                        : text === 'Quotation'
                          ? '/admin/quotation/create'
                          : text === 'Department'
                            ? '/admin/department_list'
                            : text === 'User'
                              ? '/admin/User_list'
                              : text === 'Reports'
                                ? '/admin/reports'
                                : text === 'Quotation List'
                                  ? '/admin/quotation_list'
                                  : text === 'Purchase Order'
                                    ? '/admin/purchase_order_list'
                                    : '/admin'
                  }
                  className="linktag"
                >
                  <ListItemButton>
                    <ListItemIcon>
                     
                      {text === 'Products' && <StoreIcon style={{ color: HEADER_ICON_COLOR }} />}
                      {text === 'Quotation' && <DescriptionIcon style={{ color: HEADER_ICON_COLOR }} />}
                      {text === 'Department' && <CorporateFareIcon style={{ color: HEADER_ICON_COLOR }} />}
                      {text === 'User' && <GroupIcon style={{ color: HEADER_ICON_COLOR }} />}
                      {text === 'Quotation List' && <ListAltIcon style={{ color: HEADER_ICON_COLOR }} />} 
                      {text === 'Purchase Order' && <ListAltIcon style={{ color: HEADER_ICON_COLOR }} />} 
                      {text == 'Dashboard' && <StoreIcon style={{ color: HEADER_ICON_COLOR }} /> }
                    </ListItemIcon>
                    <ListItemText style={{color:"black"}} primary={text} />
                  </ListItemButton>
                </Link>
              )}
            </ListItem>
          ))}
        </List>
        <Divider />
        {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <Main open={open}>
        {/* <DrawerHeader /> */}
        <br /><br />
        {children}
        {/* <Typography sx={{ marginBottom: 2 }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
          enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
          imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
          Convallis convallis tellus id interdum velit laoreet id donec ultrices.
          Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
          adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
          nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
          leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
          feugiat vivamus at augue. At augue eget arcu dictum varius duis at
          consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
          sapien faucibus et molestie ac.
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
          eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
          neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
          tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
          sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
          tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
          gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
          et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
          tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.
        </Typography> */}
      </Main>
    </Box>
  );
}
