import axios from 'axios';
import { API_BASE_URL } from './index';

export const restApi = (
  url: string,
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT',
  payload: any = null,
  isAuth = true
) => {
  return new Promise((resolve, reject) => {
    let httpClient = axios.create({
      baseURL: API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',  // Default Content-Type
      },
    });

    // Add Authorization header if needed
    if (isAuth) {
      httpClient.defaults.headers.common['Authorization'] =
        'Bearer ' + localStorage.getItem('token');
    }

    // Handle file uploads (FormData payload)
    if (url === "upload") {
      httpClient.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    }

    let request;

    // Make API requests based on the method
    switch (method) {
      case 'GET':
        request = httpClient.get(url, { params: payload });
        break;
      case 'POST':
        request = httpClient.post(url, payload);
        break;
      case 'PATCH':
        request = httpClient.patch(url, payload);
        break;
      case 'PUT':
          request = httpClient.patch(url, payload);
          break;  
      case 'DELETE':
        request = httpClient.delete(url, { data: payload });
        break;
      default:
        reject(new Error(`Unsupported method: ${method}`));
        return;
    }

    // Handle response and errors
    request
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          reject({
            message: error.response?.data?.message
              ? error.response?.data?.message
              : 'Internal Server Error',
          });
        } else {
          reject(error);
        }
      });
  });
};
