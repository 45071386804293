import React from 'react';
import { Row, Col, Typography, Space } from 'antd';
import { EnvironmentOutlined, PhoneOutlined, WhatsAppOutlined, MailOutlined, PrinterOutlined } from '@ant-design/icons';
import logo from "../../components/reusable/logo-chemical.png";
import Sohamlogo from "../../components/reusable/SOHAM_LOGO.png";

interface DCUniqueBiologicalHeaderProps {
    width: number;
    selecteduitable: any;
    order: any;
}

const { Text } = Typography;

const DcHeader: React.FC<DCUniqueBiologicalHeaderProps> = ({ order, width, selecteduitable }) => {
    // Style variables
    const logouniqeStyle = {
        width: selecteduitable === "SOHAM" ? "307px" : "100%",
        height: selecteduitable === "SOHAM" ? "600px" : "91px"
    };
    const headerStyle: React.CSSProperties = {
        background: '#fffcd2',
        padding: '0px 0',
        alignItems: 'center',
        marginTop: '20px',
        width: `${width}%`,
    };

    const logoStyle: React.CSSProperties = {
        position: "relative",
        clipPath: "polygon(20% 0%, 41% 0, 14% 31%, 0 14%, 83% 100%, 20% 100%, 0 100%, 0 0)",
        backgroundImage: "linear-gradient(#576236, #afc17b)",
        height: "120px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const logoWrapperStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
        borderRightStyle: 'solid',
        borderColor: '#afc17b',
        borderWidth: '3px',
    };

    const textStyle: React.CSSProperties = {
        verticalAlign: 'middle',
        backgroundColor: '#6c63d8',
        color: 'white',
        padding: '2px',
        fontSize: '10px',
    };

    const contactInfoStyle: React.CSSProperties = {
        paddingLeft: '20px',
        marginTop: '0px',
    };

    const quotationTitleStyle: React.CSSProperties = {
        display: 'inline-block',
        textAlign: 'center',
        backgroundColor: 'rgba(155, 177, 90, 0.8)',
        color: 'white',
        padding: '2px 16px',
        margin: '0 auto',
        borderBottomRightRadius: '16px',
        borderBottomLeftRadius: '16px',
    };

    const addressStyle: React.CSSProperties = {
        display: 'block',
        width: '100%',
        height: '10px',
        borderBottom: '1px solid black',
    };
    const infotitleStyle: React.CSSProperties = {
        borderRightStyle: 'solid',
        borderColor: '#afc17b',
        borderWidth: '3px',
        height: '115px'
    };

    const renderText = (icon: React.ReactNode, text: string) => (
        <Text>
            {icon} {text}
        </Text>
    );

    return (
        <>
            <Row style={headerStyle}>
                <Col xs={4} sm={4} md={4} lg={4} style={logoStyle}></Col>

                {/* Logo Section */}
                <Col xs={8} sm={8} md={8} lg={8} style={{ textAlign: 'right' }}>
                    <div style={logoWrapperStyle}>
                        <div className='header-logo'>
                            <img
                                src={selecteduitable === "SOHAM" ? Sohamlogo : logo}
                                className={`logo ${selecteduitable === "SOHAM" ? "logo1" : "logo2"}`}
                                style={logouniqeStyle}
                            />
                        </div>
                    </div>
                </Col>

                {/* Company Name and Contact Info Section */}
                <Col xs={12} sm={12} md={12} lg={12} style={contactInfoStyle}>
                    <Space direction="vertical" size="small" style={{ color: '#333', rowGap: '0px' }}>
                        {renderText(<EnvironmentOutlined style={textStyle} />, " 726/A, ‘E’ Ward, Sai Plaza, 3rd Lane, Shahupuri, Kolhapur - 416001.")}
                        {renderText(<PrinterOutlined style={textStyle} />, " Telefax: 0231-2661216,")}
                        {renderText(
                            <>
                                {' '}<PhoneOutlined rotate={115} style={{ ...textStyle, backgroundColor: 'rgb(247 127 150)' }} />
                                {' '} Mob.: 9422580305
                                {' '}<WhatsAppOutlined style={{ ...textStyle, backgroundColor: 'rgb(175 193 123)' }} />
                                {' '} 8686648282
                            </>,
                            ""
                        )}
                        {renderText(<MailOutlined style={textStyle} />, " E-mail: vinodunique@yahoo.com,  GST : 27AMNPP2410Q1ZJ")}
                    </Space>
                </Col>
            </Row>

            {/* QUOTATION  */}
            <div style={{ width: `${width}%` }}>
                <div style={{ textAlign: 'center' }}>
                    <Typography.Title level={4} style={quotationTitleStyle}>
                        DELIVERY CHALLAN
                    </Typography.Title>
                </div>

                <Row gutter={[16, 16]} style={{ marginBottom: '12px' }}>
                    {/* Left Side: "To" Section */}
                    <Col xs={12} sm={18} md={18} lg={18} style={infotitleStyle}>
                        <Typography.Text>
                            <strong>To,</strong>
                            <br />

                            {order.fullName ? 
                                <>
                                <b>
                                    {order.fullName}
                                </b> <br/>
                                <label>
                                    {order.addressInfo}
                                </label> <br/>
                                <label>
                                    {order.mobileNo}
                                </label> <br/>

                                </> : Array(3).fill(
                                
                                <>
                                    <b style={addressStyle}>
                                        { ""}
                                    </b><br />
                                </>
                            )}
                        </Typography.Text>
                    </Col>

                    {/* Right Side: D.C. No, Date, etc. */}
                    <Col xs={12} sm={6} md={6} lg={6}>
                        {/* First Row on the Right */}
                        <Row gutter={[2, 2]}>
                            <Col xs={24} sm={24}>
                                <Typography.Text strong>D.C. No:</Typography.Text>{" "}
                               <Typography.Text>{new Date(order.deliveryChallanDate).getFullYear()+"/00"+order.id+"" || "_____"}</Typography.Text>
                                                                      </Col>
                            <Col xs={24} sm={24}>
                                <Typography.Text strong>Date:</Typography.Text>{" "}
                                <Typography.Text>
                                    {order.deliveryChallanDate && order.deliveryChallanDate > 0
                                        ? new Date(order.deliveryChallanDate).toLocaleDateString('en-GB')
                                        : "_____/_____/_____"}
                                </Typography.Text>
                            </Col>
                            <Col xs={24} sm={24}>
                                <Typography.Text strong>Order No:</Typography.Text>{" "}
                                <Typography.Text>{order.orderNo || "_____"}</Typography.Text>
                            </Col>
                            <Col xs={24} sm={24}>
                                <Typography.Text strong>Order Date:</Typography.Text>{" "}
                                <Typography.Text>
                                    {order.orderDate && order.orderDate > 0
                                        ? new Date(order.orderDate).toLocaleDateString('en-GB')
                                        : "_____/_____/_____"}
                                </Typography.Text>
                            </Col>
                            <Col xs={24} sm={24}>
                                <Typography.Text strong>Department:</Typography.Text>{" "}
                                <Typography.Text>{order.department || "_____"}</Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default DcHeader;
