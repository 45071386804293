import axios from "axios";
import {restApi} from "../../../../constants/api";
export const get_product_search = async (search:string = "",companyId:any,productId?:any,unitId?:any,unitQty?:any) => {
        const response = await restApi(`product/admin/search?companyId=${companyId}&productId=${productId}&unitId=${unitId}&unitQty=${unitQty}`,"GET",{search:search});
        return response 
};

export const get_product_search_with_quotation = async (search:string = "",purchaseOrderId:any,companyId:any,productId?:any,unitId?:any,unitQty?:any) => {
        const response = await restApi(`product/admin/search_with_quotation/${purchaseOrderId}?companyId=${companyId}&productId=${productId}&unitId=${unitId}&unitQty=${unitQty}`,"GET",{search:search});
        return response 
};

export const create_order = async (payload:any) => {
        const response = await restApi(`purchase_order`,"POST",payload);
        return response 
};

export const purchase_order_list = async (page:number, limit:number, startDate:string | null  = "", endDate:string | null = "" ,
        searchInput: string | null = "",purchaseOrderId: string | null = "",orderStatus:string | null = "",
         status:string | null = ""
) => {
        const response = await restApi(`purchase_order?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&purchaseOrderId=${purchaseOrderId}&orderStatus=${orderStatus}&status=${status}`,"GET",{search:searchInput});
        return response 
};

export const order_list_total = async (startDate:string | null  = "", endDate:string | null = "" ,
        searchInput: string | null = "",purchaseOrderId: string | null = "", orderStatus:string | null = "",
         status:string | null = "") => {
        const response = await restApi(`purchase_order/total?startDate=${startDate}&endDate=${endDate}&purchaseOrderId=${purchaseOrderId}&orderStatus=${orderStatus}&status=${status}`,"GET",{search:searchInput});
        return response 
};

export const order_detail = async (orderId:string = "") => {
        const response = await restApi(`purchase_order/${orderId}`,"GET");
        return response 
};

export const order_status_update = async (payload:any,orderId:string) =>{
        const response = await restApi(`/purchase_order/${orderId}/status`,"PATCH",payload);
        return response 
}

export const get_units = async () =>{
        const response = await restApi(`/units/conversions`,"GET");
        return response 
}
export const get_gst_rates = async () =>{
        const response = await restApi(`/gst`,"GET");
        return response 
}

export const get_user = async () =>{
        const response = await restApi(`/users/search`,"GET");
        return response 
}

export const create_user = async (payload:any) =>{
        const response = await restApi(`/users/create`,"POST",payload);
        return response 
}
 

export const fetch_all_products = async () =>{
        const response = await restApi(`/product/admin/search/all`,"GET");
        return response 
}

export const update_order  = async (payload:any,purchaseOrderId:any) => {
       const response = await restApi(`/purchase_order/${purchaseOrderId}`,"PATCH",payload);
       return response 
}
 

export const insert_batch_quotation  = async (payload:any,purchaseOrderId:any) => {
        const response = await restApi(`/purchase_order/add_attribute/${purchaseOrderId}`,"POST",payload);
        return response 
 }
  

 export const fetch_batch_product  = async (data:any) => {
        const response = await restApi(`/product/maniplmanipulation`,"POST",data);
        return response 
 }
  
 export const fetch_batch_update_product  = async (data:any,orderId:any) => {
        const response = await restApi(`/product/maniplmanipulation/${orderId}`,"POST",data);
        return response 
 }
  
 export const delete_product = async (purchaseOrderId:any,rowId:any,finalPrice:any) => {
        const response = await restApi(`purchase_order/${purchaseOrderId}/${rowId}/${finalPrice}`,"DELETE",{});
        return response 
};

export const fetch_departments = async () => {
        const response = await restApi(`department`,"GET",{});
        return response 
};


export const delivery_challan_list_total = async (purchaseOrderId: string | null = "") => {
        const response = await restApi(`delivery_challan/total?purchaseOrderId=${purchaseOrderId}`,"GET");
        return response 
};

export const delivery_challan_list = async (page:number, limit:number, purchaseOrderId: string | null = "") => {
        const response = await restApi(`delivery_challan?page=${page}&limit=${limit}&purchaseOrderId=${purchaseOrderId}`,"GET");
        return response 
};

export const delivery_challan_pending_products = async (orderId:string = "",departmentId:string="") => {
        const response = await restApi(`delivery_challan/pending_products/${orderId}?departmentId=${departmentId}`,"GET");
        return response 
};

export const create_delivery_challan = async (orderId:string = "",deliveryChallanId:any, payload:any) => {
        const response = await restApi(`delivery_challan/create/${orderId}/${deliveryChallanId}`, "POST" ,payload);
        return response; 
};

export const create_delivery_challan_main = async (orderId:string = "", payload:any) => {
        const response = await restApi(`delivery_challan/create/${orderId}`, "POST" ,payload);
        return response; 
};


export const fetch_delivery_challan_products = async (deliveryChallanId:string = "") => {
        const response = await restApi(`delivery_challan/fetch/${deliveryChallanId}`,"GET");
        return response 
};
export const update_delivery_challan_main = async (deliveryChallanId:string = "", payload:any) => {
        const response = await restApi(`delivery_challan/update/${deliveryChallanId}`, "PATCH" ,payload);
        return response; 
};
export const update_delivery_challan = async (payload:any) => {
        const response = await restApi(`delivery_challan/update-isDeleted`, "POST" ,payload);
        return response; 
};
export const delivery_challanapi = async (deliveryChallanId:string) =>{
        const response = await restApi(`/delivery_challan/fetch/${deliveryChallanId}`,"GET");
        return response 
}

export const department_delivery_challan_pi = async (purchaseOrderId:string) =>{
        const response = await restApi(`/purchase_order/departments/${purchaseOrderId}`,"GET");
        return response 
}

export const get_quotation_ids = async () =>{
        const response = await restApi(`/quotation/list/dropdown`,"GET");
        return response 
}



export const billing_info_fetch_by_id = async (id:any) => {
        const response = await restApi(`billing_info/${id}`, "GET" ,{});
        return response; 
};
export const billing_info_list = async (purchaseOrderId:string) =>{
        const response = await restApi(`billing_info/purchaseOrder/${purchaseOrderId}`,"GET");
        return response 
}

export const billing_info_create = async (payload:any) =>{
        const response = await restApi(`billing_info`,"POST",payload);
        return response 
}

export const billing_info_update = async (payload:any,id:string) =>{
        const response = await restApi(`billing_info/${id}`,"PATCH",payload);
        return response 
}
