import { Box, TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import { BUTTON_STYLE } from '../../../../constants/style';

export const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  // marginLeft: '2%',
  // marginRight: '2%',
  paddingTop: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  //   // width: 600px;
  //   margin: auto;
  [theme.breakpoints.up('md')]: {
    // marginLeft: '1%',
    // marginRight: '1%',
    padding: theme.spacing(1),
  },
}));


export const AttributeContainer = styled(Box)`
margin-bottom: 16px;
 margin-top: 16px;
`;

export const TextFieldCustom = styled(TextField)`
width: 100%;
 .MuiInputLabel-asterisk {
    color: red;
    margin-left: 4px;
  }
`;

// export const FormDiv = styled(Box)`
//       background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
//       border-radius: 20px;
//       padding:20px;
//       `;
export const FormDiv = styled(Box)`
background: rgb(190 192 191 / 37%);
      box-shadow: inset 0px 0px 20px 4px #b1f1ff99;
      border-radius: 20px;
      padding: 20px;
      `;
export const ButtonCust = styled(Button)({
  ...BUTTON_STYLE
});
