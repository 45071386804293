import React, { useState, useEffect } from "react";
import { Grid, Card, Typography, Box } from "@mui/material";
import { Container } from "../styles/Dashboard.style";
import {
  getProductCount, getOrderCount, getTotalCompletedOrderAmount,
  // getOutOfStockCount,
  getOrdersPerDay, getTotalPricePerDay
} from "../services/dashboard";
import OrderPerDayUI from "./OrderPerDay";
import TotalPricePerDay from "./TotalPricePerDay";
import Quilleditor from "../../../reusable/Quilleditor";
import { Link } from "react-router-dom";
import PurchaseOrder from "./PurchaseOrder";
import { AiOutlineProduct } from "react-icons/ai";
import { CiLaptop } from "react-icons/ci";
import { FaRegListAlt } from "react-icons/fa";
import { CiCircleCheck } from "react-icons/ci";
import { FaRegCheckCircle } from "react-icons/fa";
import "../styles/dashboard.css";

const Dashboard: React.FC = () => {
  const [productCount, setProductCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [totalCompletedOrderAmount, setTotalCompletedOrderAmount] = useState(0);
  const [outOfStockCount, setOutOfStockCount] = useState(0);
  const [ordersPerDay, setOrdersPerDay] = useState([]);
  const [totalPricePerDay, setTotalPricePerDay] = useState([]);


  const [editorValue, setEditorValue] = useState('');


  useEffect(() => {
    fetchProductCount();
    fetchOrderCount();
    fetchTotalCompletedOrderAmount();
    // fetchOutOfStockCount();
    fetchOrdersPerDay();
    fetchTotalPricePerDay();
  }, [])
  const fetchProductCount = async () => {
    try {
      const response: any = await getProductCount();
      setProductCount(response.data?.totalCount || 0)
    } catch (error) {

    }
  }
  const fetchOrderCount = async () => {
    try {
      const response: any = await getOrderCount();
      setOrderCount(response.data?.totalCount || 0)
    } catch (error) {

    }
  }
  const fetchTotalCompletedOrderAmount = async () => {
    try {
      const response: any = await getTotalCompletedOrderAmount();
      setTotalCompletedOrderAmount(response.data?.totalCount || 0)
    } catch (error) {
    }
  }
  // const fetchOutOfStockCount = async () => {
  //   try {
  //     const response: any = await getOutOfStockCount();
  //     setOutOfStockCount(response.data?.outOfStockCount || 0)
  //   } catch (error) {
  //   }
  // }
  const fetchOrdersPerDay = async () => {
    try {
      const response: any = await getOrdersPerDay();
      setOrdersPerDay(response.data?.ordersPerDay)
    } catch (error) {
    }
  }
  const fetchTotalPricePerDay = async () => {
    try {
      const response: any = await getTotalPricePerDay();
      setTotalPricePerDay(response.data?.totalPricePerDay)
    } catch (error) {
    }
  }
  return (
    <div className="dashboardbgcolor">
    <Container style={{padding: '10px'}}>
      {/* <h4 style={{ fontFamily: 'auto', fontWeight: '500' }}>Dashboard</h4> */}
      {/* <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
          <span style={{ fontSize: '20px', fontFamily: 'auto', fontStyle: 'italic' }} >UNIQUE BIOLOGICAL & CHEMICALS</span>
        </Grid>
      </Grid> */}
      <br />

      {/* <Quilleditor
                value={editorValue}
                setValue={setEditorValue}
            /> */}
      <div>
        {
          <div dangerouslySetInnerHTML={{ __html: editorValue }} />
        }
      </div>
      <Grid container spacing={4}>
        {/* <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Link to='/admin/product_list' style={{ textDecoration: 'none' }}>
            <Card sx={{ width: "100%", height: "80px", backgroundColor: "#CBDCEB" }}>
              <Typography variant="h4" style={{ paddingTop: '10px', paddingLeft: '30px' }}>
                {productCount}
              </Typography>
              <Typography variant="body2" style={{ paddingLeft: '30px', fontStyle: 'italic', fontSize: '15px' }}>
                Product
              </Typography> 
              <AiOutlineProduct size={24} color="blue" />
            </Card>
          </Link>
        </Grid> */}

        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Link to="/admin/product_list" style={{ textDecoration: "none" }}>
            <Card
              sx={{
                width: "100%",
                height: "80px",
                // backgroundColor: "#CBDCEB",
                backgroundImage: "linear-gradient(45deg, #c5fff79c, transparent)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 20px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}
            >
              {/* Left Section: Product Count and Label */}
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    color: "#000",
                    fontSize: '27px'  
                  }}
                >
                  {productCount}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontStyle: "italic",
                    fontSize: "15px",
                    color: "#555",
                  }}
                >
                  Product
                </Typography>
              </Box>

              {/* Right Section: Icon */}
              <div style={{ backgroundColor: "rgb(242 194 199 / 26%)", width: '50px',
               height: '50px', borderRadius: "10px" }}>
                
                <AiOutlineProduct size={28} color="rgb(255 99 241 / 95%)" style={{ margin: '11px' }} />
              </div>
            </Card>
          </Link>
        </Grid>

        {/* <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Link to='/admin/quotation_list' style={{ textDecoration: 'none' }}>
            <Card sx={{ width: "100%", height: "80px", backgroundColor: "#98d3ff" }}>
              <Typography variant="h4" style={{ paddingTop: '10px', paddingLeft: '30px' }}>
                {orderCount}
              </Typography>
              <Typography variant="body2" style={{ paddingLeft: '30px', fontStyle: 'italic', fontSize: '15px' }}>
                Quotation
              </Typography>
            </Card>
          </Link>
        </Grid> */}

        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Link to="/admin/quotation_list" style={{ textDecoration: "none" }}>
            <Card
              sx={{
                width: "100%",
                height: "80px",
                // backgroundColor: "#98d3ff",
                backgroundImage: "linear-gradient(45deg, #c5fff79c, transparent)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 20px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}
            >
              {/* Left Section: Product Count and Label */}
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    color: "#000",
                    fontSize: '27px'
                  }}
                >
                  {orderCount}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontStyle: "italic",
                    fontSize: "15px",
                    color: "#555",
                  }}
                >
                  Quotation
                </Typography>
              </Box>

              {/* Right Section: Icon */}
              <div style={{ backgroundColor: "rgb(190 191 229 / 59%)", width: '50px', height: '50px', borderRadius: "10px" }}>
                <FaRegListAlt size={26} color="rgb(134 107 255)" style={{ margin: '11px' }} />
              </div>
            </Card>
          </Link>
        </Grid>

        {/* <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Link to='/admin/quotation_list' style={{ textDecoration: 'none' }}>
            <Card sx={{ width: "100%", height: "80px", backgroundColor: "#d6f0ff" }}>
              <Typography variant="h4" style={{ paddingTop: '10px', paddingLeft: '30px' }}>
                {totalCompletedOrderAmount}
              </Typography>
              <Typography variant="body2" style={{ paddingLeft: '30px', fontStyle: 'italic', fontSize: '15px' }}>
                Quotation Granted
              </Typography>
            </Card>
          </Link>
        </Grid> */}

        <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
          <Link to="/admin/quotation_list" style={{ textDecoration: "none" }}>
            <Card
              sx={{
                width: "100%",
                height: "80px",
                // backgroundColor: "#d6f0ff",
                backgroundImage: "linear-gradient(45deg, #c5fff79c, transparent)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 20px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
              }}
            >
              {/* Left Section: Product Count and Label */}
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    color: "#000",
                    fontSize: '27px'
                  }}
                >
                  {totalCompletedOrderAmount}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontStyle: "italic",
                    fontSize: "15px",
                    color: "#555",
                  }}
                >
                  Quotation Granted
                </Typography>
              </Box>

              {/* Right Section: Icon */}
              <div style={{ backgroundColor: "rgb(159 237 158 / 35%)", width: '50px', height: '50px', borderRadius: "10px" }}>
                <FaRegCheckCircle size={28} color="rgb(95 233 95 / 95%)" style={{ margin: '11px' }} />
              </div>
            </Card>
          </Link>
        </Grid>

      </Grid>
      <br />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6}>
          <Card style={{ padding: '15px', boxShadow: "rgba(0, 0, 0, 0.1) 1px 0px 12px 7px" }}>
            <Typography variant="h5" style={{ fontSize: '20px', fontFamily: 'auto', fontStyle: 'italic' }}>Quotation Current Month</Typography>
            <OrderPerDayUI data={ordersPerDay} />
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Card style={{ padding: '15px', boxShadow: "rgba(0, 0, 0, 0.1) 1px 0px 12px 7px" }}>
            <Typography variant="h5" style={{ fontSize: '20px', fontFamily: 'auto', fontStyle: 'italic' }}>Quotation of Year</Typography>
            <TotalPricePerDay data={totalPricePerDay} />
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5" style={{ fontSize: '20px', fontFamily: 'auto', fontStyle: 'italic' }}>Purchase Order</Typography>
          <PurchaseOrder />
        </Grid>

      </Grid>
      <br />  <br />
    </Container>
    </div>
  )
}
export default Dashboard;

