import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Upload, message, Form, Card,Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {insertDeliveryChallanCount} from "../../../../../constants/index";
import {fetch_delivery_challan_products, update_delivery_challan, update_delivery_challan_main,
  department_delivery_challan_pi
} from "../../services/order";
import * as XLSX from "xlsx";
import { Link , useNavigate} from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { TextField } from "@mui/material";

const { Option } = Select;
const UpdateDeliveryChallan = () => {
  const [options, setOptions] = useState<any[]>([]);
  const [fileList, setFileList] = useState([]);
    const navigate = useNavigate();
    const {deliveryChallanId,purchaseOrderId} = useParams();
    const [loading,setLoading] = useState(false);
    const [pendingProducts,setPendingProducts] = useState([]);
    const [uploadedData, setUploadedData] = useState([]);
    const [deliveryChallanDate, setDeliveryChallanDate] = useState<Dayjs | null>(null);
    const [orderDate, setOrderDate] = useState<Dayjs | null>(null);
    const [orderNo, setOrderNo] = useState<any>();
    const [departmentId, setDepartmentId] = useState<any>();

    const downloaddeliveryChallanProducts = async () => {
        setLoading(true);
        try {
            console.log(deliveryChallanId)
        if(deliveryChallanId){
            const response:any = await fetch_delivery_challan_products(deliveryChallanId);
            console.log(response)
            if(response?.data?.deliveryChallan && response?.data?.deliveryChallan.length > 0){
                 setDeliveryChallanDate(response?.data?.deliveryChallan[0].deliveryChallanDate
                      ? dayjs(response?.data?.deliveryChallan[0].deliveryChallanDate)
                      : null)
                      setOrderDate(response?.data?.deliveryChallan[0].orderDate
                        ? dayjs(response?.data?.deliveryChallan[0].orderDate)
                        : null)
                        setOrderNo(response?.data?.deliveryChallan[0].orderNo
                          ? response?.data?.deliveryChallan[0].orderNo
                          : null)     
                          setDepartmentId(response?.data?.deliveryChallan[0].departmentId
                            ? dayjs(response?.data?.deliveryChallan[0].departmentId)
                        : null)
                        }
            if(response?.data?.deliveryChallanProducts && response.data.deliveryChallanProducts.length > 0)
            setPendingProducts(response?.data?.deliveryChallanProducts)
            exportToXLSX(response?.data?.deliveryChallanProducts)
        }
            } catch (error) {
                console.error('Error fetching orders:', error);
            } finally {
                setLoading(false);
            }
    }
    console.log(pendingProducts)
    const exportToXLSX = (pendingProducts:any) => {
        if (pendingProducts.length === 0) {
            console.error("No data available for export.");
            return;
        }

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Convert JSON data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(pendingProducts);

        // Append worksheet to workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Pending Products");

        // Generate Excel file and trigger download
        XLSX.writeFile(workbook, "update_delivery_challan_products_"+new Date().getTime()+".xlsx");
    };
   
    const splitArrayIntoChunks = (array: any[], chunkSize: number) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
      };
      const updateDeliveryChallanInChunks = async (deliveryChallanId: any, ids: any[]) => {
        const chunks = splitArrayIntoChunks(ids, insertDeliveryChallanCount);
      
        for (const chunk of chunks) {
          const payload = {
            deliveryChallanId,
            ids: chunk,
          };
      
          try {
            const data: any = await update_delivery_challan(payload);
      
            if (data?.data && data?.data?.error) {
              message.error(data?.data?.error);
            } 
          } catch (error) {
            message.error("An error occurred while updating the Delivery Challan.");
            console.error(error);
          }
        }
      }; 
    const handleFileUpload = (file: File) => {
        const reader = new FileReader();

        reader.onload = async (e: any) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: "binary" });

            // Assuming data is in the first sheet
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);

            // Filter data where isdeleted == 1
            const filteredData:any = data.filter((item: any) => item.isdeleted === 1);

            console.log("Filtered Data:", filteredData);
            setUploadedData(filteredData);
            const ids = filteredData.map((item:any) => item.id);
            console.log(ids)
            if ( deliveryChallanDate) {
            let payload_delivery_Challan = {
              deliveryChallanDate: deliveryChallanDate ? deliveryChallanDate.valueOf()  : "",
              orderDate: orderDate ? orderDate.valueOf()  : "",
              orderNo: orderNo ? orderNo  : "",
              // departmentId: departmentId ? departmentId  : ""
          }
          
           const deliveryChallanIdData:any = await update_delivery_challan_main(deliveryChallanId,payload_delivery_Challan);
         
            if(ids && ids.length > 0){
                  updateDeliveryChallanInChunks(deliveryChallanId, ids)
                  
            }
            message.success("Delivery Challan updated successfully!");
            navigate(`/admin/delivery-challan/${purchaseOrderId}`);
          } else{
            message.error("Please select delivery challan date.");
          }
            
        };

        reader.onerror = () => {
            message.error("Error reading the file!");
        };

        reader.readAsBinaryString(file);
    };

    const uploadProps = {
      beforeUpload: (file: File) => {
        handleFileUpload(file);
        setFileList([]);
        return false; // Prevent automatic upload
    },
    fileList, // Controlled file list
    onRemove: () => setFileList([]),
    };

    useEffect(() => {
      const fetchData = async () => {
        if(purchaseOrderId){
          setLoading(true);
          try {
            const response:any = await department_delivery_challan_pi(purchaseOrderId);
            setOptions(response.data.departments);
          } catch (err) {
          } finally {
            setLoading(false);
          }
        }
       
      };
  
      fetchData();
    }, []);
    return (
        <div>
      <div style={{ maxWidth: 450, margin: "0 auto", padding: 20 }}>
      <Card
        title="Update Delivery Challann"
        bordered={false}
        style={{
          boxShadow: "0px 0px 5px 4px #f9f9dd",
          borderRadius: 8,
        }}
      >
        <Form layout="vertical">
          {/* Delivery Challan Date */}
          {
            pendingProducts && pendingProducts.length > 0 &&

            <div> 
              <Form.Item
            label={""}
          >
          <LocalizationProvider dateAdapter={AdapterDayjs}
                                            >
                                              <DatePicker
                                                label={
                                                  <>
                                                    Delivery Challan Date<span style={{ color: "red" }}>*</span>
                                                  </>
                                                }
                                                value={deliveryChallanDate}
                                                onChange={(newValue) => setDeliveryChallanDate(newValue)}
                                                slotProps={{ textField: { size: "small" } }}
                              
                                              />
                                            </LocalizationProvider>
          </Form.Item>
         
           <Form.Item
            label={""}
          >
          <LocalizationProvider dateAdapter={AdapterDayjs}
                                            >
                                              <DatePicker
                                                label={
                                                  <>
                                                    Order Date<span style={{ color: "red" }}>*</span>
                                                  </>
                                                }
                                                value={orderDate}
                                                onChange={(newValue) => setOrderDate(newValue)}
                                                slotProps={{ textField: { size: "small" } }}
                              
                                              />
                                            </LocalizationProvider>
          </Form.Item>
<TextField
                label={<>
                  Order No
                  <span style={{ color: "red" }}>*</span>
                </>}
                InputLabelProps={{
                  shrink: orderNo ? true : false, // Ensure label stays above the input when it has a value
                }}
                id="orderNo"
                type="text"
                size="small"
                value={orderNo}
                onChange={(e: any) => setOrderNo(e.target.value)}
                variant="outlined"

              />
              
            </div>
           
          }
            
            <br/>
          {/* Action Buttons */}
          <div style={{ display: "flex", gap: 10, marginBottom: 20 }}>
           
            <Button
              type="primary"
              loading={loading}
              onClick={()=>downloaddeliveryChallanProducts()}
            >
              Download Products for Update
            </Button>
            <Upload {...uploadProps} >
            <Button icon={<UploadOutlined />} disabled={loading} loading={loading}>Upload XLSX</Button>
          </Upload>
          </div>
          <Link to={`/admin/delivery-challan/${purchaseOrderId}`}>
              <Button>Back</Button>
            </Link>
        
         
        </Form>
      </Card>
    </div>

           
        </div>
    )
}

export default UpdateDeliveryChallan;