import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Upload, message, Form , Card, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {delivery_challan_pending_products, create_delivery_challan, create_delivery_challan_main,
  department_delivery_challan_pi
} from "../../services/order";
import * as XLSX from "xlsx";
import { insertDeliveryChallanCount } from "../../../../../constants";
import { Link } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { TextField } from "@mui/material";
const { Option } = Select;
const CreateDeliveryChallan = () => {
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();
    const {purchaseOrderId} = useParams();
    const [loading,setLoading] = useState(false);
    const [pendingProducts,setPendingProducts] = useState([]);
    const [uploadedData, setUploadedData] = useState([]);
    const [deliveryChallanDate, setDeliveryChallanDate] = useState<Dayjs | null>(null);
 const [options, setOptions] = useState<any[]>([]);
 const [orderDate, setOrderDate] = useState<Dayjs | null>(null);
     const [orderNo, setOrderNo] = useState<any>();
     const [departmentId, setDepartmentId] = useState<any>();
    const downloadPendingProductsForDeliveryChallan = async () => {
        setLoading(true);
        try {
            console.log(purchaseOrderId)
        if(purchaseOrderId){
            const response:any = await delivery_challan_pending_products(purchaseOrderId,departmentId);
            console.log(response)
            if(response?.data?.quotationProducts && response.data.quotationProducts.length > 0)
            setPendingProducts(response?.data?.quotationProducts)
          if(response?.data?.quotationProducts.length == 0){
            message.error("No more products are available");
          }
            exportToXLSX(response?.data?.quotationProducts)
        }
            } catch (error) {
                console.error('Error fetching orders:', error);
            } finally {
                setLoading(false);
            }
    }
    console.log(pendingProducts)
    const exportToXLSX = (pendingProducts:any) => {
        if (pendingProducts.length === 0) {
            console.error("No data available for export.");
            return;
        }

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Convert JSON data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(pendingProducts);

        // Append worksheet to workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Pending Products");

        // Generate Excel file and trigger download
        XLSX.writeFile(workbook, "pending_products_"+new Date().getTime()+".xlsx");
    };
   

    const handleFileUpload = (file: File) => {
        const reader = new FileReader();

        reader.onload = async (e: any) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: "binary" });

            // Assuming data is in the first sheet
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);

            // Filter data where isTaken_in_Challan == 1
            const filteredData:any = data.filter((item: any) => item.isTaken_in_Challan === 1);

            console.log("Filtered Data:", filteredData);
            setUploadedData(filteredData);
            const ids = filteredData.map((item:any) => item.id);
            console.log(ids)
            if (ids && ids.length > 0 && deliveryChallanDate) {
                const payload_delivery_Challan = {
                  deliveryChallanDate: deliveryChallanDate ? deliveryChallanDate.valueOf()  : "",
                  quotationProductIds: filteredData.filter((e:any,index:any) => index < 20).map((item:any,index:any) => item.id),
                  orderDate: orderDate ? orderDate.valueOf()  : "",
                  orderNo: orderNo ? orderNo  : "",
                  departmentId: departmentId ? departmentId  : ""
                };
              
                try {
                  const deliveryChallanIdData: any = await create_delivery_challan_main(purchaseOrderId, payload_delivery_Challan);
              
                  if (deliveryChallanIdData?.data?.insertId > 0) {
                    const deliveryChallanId = deliveryChallanIdData.data.insertId;
              
                    const splitArrayIntoChunks = (array: any[], chunkSize: number) => {
                      const chunks = [];
                      for (let i = 0; i < array.length; i += chunkSize) {
                        chunks.push(array.slice(i, i + chunkSize));
                      }
                      return chunks;
                    };
              
                    const chunks = splitArrayIntoChunks(ids, insertDeliveryChallanCount);
              
                    for (const chunk of chunks) {
                      const payload = {
                        quotationProductIds: chunk,
                      };
              
                      const data: any = await create_delivery_challan(purchaseOrderId, deliveryChallanId, payload);
              
                      if (data?.data && data?.data?.error) {
                        message.error(data?.data?.error);
                      } else {
                       
                      }
                    }
                    message.success("Delivery Challan created successfully!");
                    navigate(`/admin/delivery-challan/${purchaseOrderId}`);
                  } else {
                    message.error("Please provide valid pending product list to create delivery challan.");

                    navigate(`/admin/delivery-challan/${purchaseOrderId}`);
                  }
                } catch (error) {
                  message.error("An error occurred while creating the Delivery Challan.");
                  console.error(error);
                }
              }
              else{
                message.error("Please select product in file then upload or delivery challan date.");
            }
            
        };

        reader.onerror = () => {
            message.error("Error reading the file!");
        };

        reader.readAsBinaryString(file);
    };

    const uploadProps = {
        beforeUpload: (file: File) => {
            handleFileUpload(file);
            setFileList([]);
            return false; // Prevent automatic upload
        },
        fileList, // Controlled file list
        onRemove: () => setFileList([]),
    };
 useEffect(() => {
      const fetchData = async () => {
        if(purchaseOrderId){
          setLoading(true);
          try {
            const response:any = await department_delivery_challan_pi(purchaseOrderId);
            setOptions(response.data.departments);
          } catch (err) {
          } finally {
            setLoading(false);
          }
        }
       
      };
  
      fetchData();
    }, []);
    return (
        <div>
           
            <div style={{ maxWidth: 450, margin: "0 auto", padding: 20 }}>
              <Card style={{boxShadow: "rgb(179 179 179) 1px 1px 5px 4px", marginTop:'30px'}}>
              <h1>Create Delivery Challan</h1>
      <Form layout="vertical">
        {/* Delivery Challan Date */}
        <Form.Item
          label={""}
        >
        <LocalizationProvider dateAdapter={AdapterDayjs}
                                          >
                                            <DatePicker
                                              label={"Delivery Challan Date"}
                                              value={deliveryChallanDate}
                                              onChange={(newValue) => setDeliveryChallanDate(newValue)}
                                              slotProps={{ textField: { size: "small" } }}
                            
                                            />
                                          </LocalizationProvider>
        </Form.Item>

         
           <Form.Item
            label={""}
          >
          <LocalizationProvider dateAdapter={AdapterDayjs}
                                            >
                                              <DatePicker
                                                label={
                                                  <>
                                                    Order Date<span style={{ color: "red" }}>*</span>
                                                  </>
                                                }
                                                value={orderDate}
                                                onChange={(newValue) => setOrderDate(newValue)}
                                                slotProps={{ textField: { size: "small" } }}
                              
                                              />
                                            </LocalizationProvider>
          </Form.Item>
<TextField
                label={<>
                  Order No
                  <span style={{ color: "red" }}>*</span>
                </>}
                InputLabelProps={{
                  shrink: orderNo ? true : false, // Ensure label stays above the input when it has a value
                }}
                id="orderNo"
                type="text"
                size="small"
                value={orderNo}
                onChange={(e: any) => setOrderNo(e.target.value)}
                variant="outlined"

              />
               <div style={{ width: 200,paddingTop: "20px",
                      paddingBottom: "20px" }}>
                     <label>Department</label>   
      <Select
        placeholder="Select department"
        style={{ width: "100%",height: "43px" }}
        loading={loading}
        showSearch
        value={departmentId}
        onChange={(e:any) => {
              setDepartmentId(e)
        } }
        filterOption={(input:any, option:any) =>
          option?.children.toLowerCase().includes(input.toLowerCase())
        }
      >
       { (
         options && options.length > 0 && options.map((item: any) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))
        )}
      </Select>
    </div>
        {/* Action Buttons */}
      { departmentId &&  <div style={{ display: "flex", gap: 10, marginBottom: 20 }}>
         
          <Button type="primary" loading={loading} onClick={() => downloadPendingProductsForDeliveryChallan()}>
            Download Pending Products
          </Button>
          <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>Upload XLSX</Button>
        </Upload>
        </div> }

        {/* Upload Section */}
      
        <Link to={`/admin/delivery-challan/${purchaseOrderId}`}>
            <Button>Back</Button>
          </Link>
      </Form>
              </Card>
     
    </div>
        </div>
    )
}

export default CreateDeliveryChallan;